import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, markers, FLAVOR } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import { downloadPDF, fixDate, getMapMarker, staticSort } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import Searchbar from '../Searchbar/Searchbar';
import AddDevice from "../../components/Modals/AddDevice";
import ShareDevice from "../../components/Modals/ShareDevice";
import { useContextData } from '../../components/ContextData';
import DeviceModal from '../Modals/DeviceModal';
import { Collapse } from '@mui/material';
import { useIsMobile } from '../../helpers/customHooks';
import toast from 'react-hot-toast';
import AlarmCentralModal from '../Modals/AlarmCentralModal';

const Devices = (props) => {
    const MOBILE = useIsMobile();
	const { t } = useTranslation();
    const { deviceListCompact, setDeviceListCompact } = useContextData();
    const [myDevicesFull, setMyDevicesFull] = useState([])
    const [myDevices, setMyDevices] = useState([])
    const [sharedDevicesFull, setSharedDevicesFull] = useState([])
    const [sharedDevices, setSharedDevices] = useState([])
    const [pendingInvites, setPendingInvites] = useState([])
    const [addDevice, setAddDevice] = useState(false)
    const [shareDevice, setShareDevice] = useState(false)
    const [allDevices, setAllDevices] = useState([])
    const [openDevice, setOpenDevice] = useState()
    const [currentIcon, setCurrentIcon] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState()
    const [centrals, setCentrals] = useState([])
    const [alarmCentralShow, setAlarmCentralShow] = useState(false)
    const [showAlarmModal, setShowAlarmModal] = useState(false)
    const [alarmType, setAlarmType] = useState()
    const [testInfo, setTestInfo] = useState()
    useEffect(() => {
        getDevices()
        if(process.env.REACT_APP_FLAVOR === "tracking"){
            getCentralInfo()
        }
    }, [])

    const getCentralInfo = () => {
        request.get("alarmcenter/central/info")
            .then((response) => {
                setCentrals(response.data.response)
            })
            .catch((error) => {
                console.error(error)
            })
        
    }

    const getDevices = () => {
        request.get("devices/compactinfo").then((response) => {
            setDeviceListCompact(response.data.devices)
            const devices = response.data.devices
            const pending = response.data.pendingSharedDevices
            const shared = devices?.filter((device) => device.sharedstatus.sharedwithme)
            const mine = devices?.filter((device) => !device.sharedstatus.sharedwithme)
            let sortedShared = staticSort(shared, "name")
            let sortedMine = staticSort(mine, "name")            
            setAllDevices(devices)
            setMyDevices(sortedMine ?? [])
            setMyDevicesFull(sortedMine ?? [])
            setSharedDevices(sortedShared ?? [])
            setSharedDevicesFull(sortedShared ?? [])
            setPendingInvites(pending  ?? [])
            setLoading(false)
        }) 
    }

    const getTestInfo = (confirm = false) => {
        request.post("", "alarmcenter/device/installationtest", {deviceid: openDevice.id, confirm: confirm}).then((response) => {  
            if(confirm){
                setShowAlarmModal(false)
                getDevices()
            }else{
                setTestInfo(response.data.response)
            }
        })
    }

    const filterSearch = (searchWord) => {
			if(!searchWord || searchWord === ""){
                setMyDevices(myDevicesFull)
                setSharedDevices(sharedDevicesFull)
				return
			}
            let arrayMy = myDevicesFull;
			let matchMy = arrayMy?.filter((device) => {
			const item = searchWord.toLowerCase();
			return (
				device.name.toLowerCase().indexOf(item) > -1 ||
				device.uniqueid.toLowerCase().indexOf(item) > -1
			  );
			})
            let arrayShared = sharedDevicesFull
            let matchShared = arrayShared?.filter((device) => {
            const item = searchWord.toLowerCase();
            return (
                device.name.toLowerCase().indexOf(item) > -1 ||
                device.uniqueid.toLowerCase().indexOf(item) > -1
                );
            })
			setMyDevices(matchMy)
			setSharedDevices(matchShared)
	}

    useEffect(() => {
        if(openDevice){
            setCurrentIcon(
                {
                    icon: openDevice.mapicon.split("_")[0],
                    color: openDevice.mapicon.split("_")[1]
                }
            )
            props.setIsOpen(true)
        }
    }, [openDevice])

    const openModal = (type) => {
        if(type === "imei" || type === "battery"){
            return
        }
        setType(type)
        setShowModal(!showModal)
    }

    const openAlarmModal = (type) => {  
        const centralid = openDevice?.modelspecificinfo?.alarmcenter?.centerid
        if(type === "central" && centralid !== null){
            toast.error(t("toast.unlink_central_first"))
            return
        }
        setShowAlarmModal(!showAlarmModal)
        setAlarmType(type)
        if(type === "installation_test"){
            getTestInfo()
        }
    }

    useEffect(() => {
        setOpenDevice()
        props.setIsOpen(false)        
    }, [props.closeDevice])

    const saveData = (data, reset = false) => {

        console.log(data);
        let url;
        if(type === "override" || type === "engine_block"){
            url = "devices/external/command"
        }else if(process.env.REACT_APP_FLAVOR === "bike" || (process.env.REACT_APP_FLAVOR === "alco")){
            url = "device/update"
        }else{
            url = "device/update-new"
        }

        request.post("", url, data).then((response) => {
            if(type === "override" || type === "engine_block"){
                if(response?.data?.deniedDevices?.length > 0){
                    toast.error(response?.data?.deniedDevices[0].reason)
                }else{
                    toast.success(t("toast.success"))
                    if(!reset){
                        setShowModal(!showModal)
                    }
                    getDevices()
                }
                return
            }
            if(response.data?.successful){
                if(!reset){
                    toast.success(t("toast.success"))
                    setShowModal(!showModal)
                }
                getDevices()
            }else{
                toast.error(t("toast.error"))
            }
        })
    }


    const saveDataAlarm = (data, reset = false) => {
        let url = process.env.REACT_APP_FLAVOR === "bike" ? "device/update" : "device/update-new"
        request.post("", url, data).then((response) => {
            if(response.data?.successful){
                if(!reset){
                    toast.success(t("toast.success"))
                    setShowAlarmModal(!showAlarmModal)
                }
                getDevices()
            }else{
                toast.error(t("toast.error"))
            }
        })
    }

    useEffect(() => {
        if(openDevice){
            let updatedDevice = allDevices.find(device => device.id === openDevice.id)
            setOpenDevice(updatedDevice)
        }
    }, [allDevices, openDevice])

    const removeShared = (pending, id, email) => {
        if(pending){
            request.post("", "device/share/revokependinginvitation", {deviceid: [openDevice.id], email: [email]}).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getDevices()
                }
            })
            
        }else{
            request.post("", "device/delete", {deviceid: [openDevice.id], sharedid: [id]}).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getDevices()
                }
            })  
        }
    }

    const deleteData = (type) => {
        let url;
        let id;
        switch(type){
            case "delete_history":
                url = "history/delete"
                id = openDevice.id
                break;
            case "delete_device":
                url = "device/delete"
                id = [openDevice.id]
                break;
            default: return
        }
        request.post("", url, {deviceid: id}).then((response) => {            
            if(response.data.successful){
                setShowModal(!showModal)
                getDevices()
                setOpenDevice()
                
                if(response.data.failed_devices?.length > 0){
                    let device = response.data.failed_devices[0]
                    let content = 
                    <div className="d-flex flex-column">
                        <p>{t("settings.couldnt_delete")}: {device.name}</p>
                        <p>{t("settings.reason")}: {device.reason}</p>
                    </div>
                    toast.error(content)
                }else{
                    toast.success(t("toast.done"))
                }
            }
        })
    }


    const sendTestAlarm = () => {
        return request.post("", "alarmcenter/device/sendtestalarm", {deviceid: openDevice?.id, confirm: true})
        .then((response) => {
            console.log("Parent response: ", response);
            if(response.data.error){
                toast.error(t(response.data.message))
            }
            return response;
        })
        .catch((error) => {
            toast.error(t("toast.error"))
            console.error("Error: ", error);
            throw error;
        });
    };

    const downloadCertificate = () => {
        return request.generatePDFpost("alarmcenter/device/installation/certification/generate", {deviceid: openDevice?.id})
        .then((response) => {
            if(response.status === 404){
                toast.error(t("toast.error"))
            }else{
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                console.log("URL: ", url);
                
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                link.download = "swetrack_certificate"; // Set the file name for the download
            
                // Append the link to the document, trigger a click, and then remove it
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            
                // Clean up the URL object
                window.URL.revokeObjectURL(url);
            }

        
        })
        .catch((error) => {
            toast.error(t("toast.error"));
            console.error("Error: ", error);
            throw error;
        });
    };

    const info = [
        {
            valid: openDevice && (FLAVOR === "bike" ? openDevice.communicationmode.vehiclebattery.valid : openDevice.visibleswitch?.editdevice.battery_external),
            title: t("settings.battery_external"),
            data: openDevice && (FLAVOR === "bike" ? `${openDevice.communicationmode.vehiclebattery.info}%` : `${openDevice.modelspecificinfo.externalbattery.externalbatteryinfo} V`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? openDevice.communicationmode.devicebattery.valid : openDevice.visibleswitch?.editdevice.battery_internal),
            title: t("settings.battery_internal"),
            data: openDevice && (FLAVOR === "bike" ? `${openDevice.communicationmode.devicebattery.info}%` : `${openDevice.modelspecificinfo.internalbattery.internalbatteryinfo ? openDevice.modelspecificinfo.internalbattery.internalbatteryinfo : 0}%`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.temperature) ,
            title: t("settings.temperature"),
            data: openDevice && (FLAVOR === "bike" ? null : `${(openDevice.modelspecificinfo.temp_hum?.currentdata.temperature/100).toFixed(1)} ${openDevice.modelspecificinfo.temp_hum?.currentsettings.tempType === 1 ? "°F" : "°C"}`)
        },
        {
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.humidity),
            title: t("settings.humidity"),
            data: openDevice && (FLAVOR === "bike" ? null : `${(openDevice.modelspecificinfo.temp_hum?.currentdata.humidity/100).toFixed(1)}%`)
        },

    ]
    const options = [
        {
            name: "devicename",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.name),
            title: t("settings.name"),
            data: openDevice && openDevice.name,
            icon: icons.dots_vertical,
            function: () => openModal("devicename")
        },
        {
            name: "imei",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.imei),
            title: FLAVOR === "bike" ? t("settings.vin") : t("settings.imei"),
            data: openDevice && (FLAVOR === "bike" ? openDevice.vinnumber : openDevice.uniqueid) 
        },
        {
            name: "speedlimit",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarm_speed),
            title: t("settings.speed_settings"),
            data: openDevice && openDevice.modelspecificinfo.speedlimit.speedlimitinfo ? `${openDevice.modelspecificinfo.speedlimit.speedlimitinfo} ${openDevice.modelspecificinfo.speedlimit.speedlimitunit}` : t("settings.not_set"),
            icon: icons.dots_vertical,
            function: () => openModal("speedlimit")

        },
        {
            name: "map_icon",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.mapicon),
            title: t("settings.map_icon"),
            data: openDevice && getMapMarker(openDevice?.mapicon, true),
            icon: icons.dots_vertical,
            img: true,
            function: () => openModal("map_icon")

        },
        {
            name: "powersaving",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.powersavingmode) ,
            title: t("settings.powersaving"),
            icon: icons.dots_vertical,
            function: () => openModal("powersaving")


        },
        {
            name: "wakeup",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.wakeupmode),
            title: t("settings.wakeup"),
            icon: icons.dots_vertical,
            function: () => openModal("wakeup")

        },
        {
            name: "temperature",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarm_temp),
            title: t("settings.temperature"),
            icon: icons.dots_vertical,
            function: () => openModal("temperature")

        },
        {
            name: "humidity",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarm_hum),
            title: t("settings.humidity"),
            icon: icons.dots_vertical,
            function: () => openModal("humidity")

        },
        {
            name: "alarm_central",
            valid: openDevice && (FLAVOR === "bike" ? false : openDevice.visibleswitch?.editdevice.alarmcentral),
            title: t("settings.alarm_central"),
            icon: icons.right_arrow_dark,
            function: () => setAlarmCentralShow(!alarmCentralShow)

        },
        {
            name: "override",
            valid: openDevice && openDevice.visibleswitch?.editdevice?.override,
            title: t("settings.override"),
            icon: icons.dots_vertical,
            data: openDevice && openDevice?.externalsystem_alcolock?.override?.pending.active ? t("settings.pending") : openDevice?.externalsystem_alcolock?.override?.current?.active ? openDevice?.externalsystem_alcolock?.override?.current?.overrideuntil : t("settings.no"),
            function: () => openModal("override")
        },
        {
            name: "engine_block",
            valid: openDevice && openDevice.visibleswitch?.editdevice?.engineblock,
            title: t("settings.engine_block"),
            icon: icons.dots_vertical,
            data: openDevice && openDevice?.externalsystem_alcolock?.engineblock?.pending ? t("settings.pending") : openDevice?.externalsystem_alcolock?.engineblock?.status ? t("settings.yes"): t("settings.no"),
            function: () => openModal("engine_block")
        },
        {
            name: "sharing",
            valid: openDevice && openDevice?.sharedstatus?.sharedwithme ? false : openDevice?.visibleswitch?.editdevice?.sharing,
            title: t("settings.sharing_status"),
            icon: icons.right_arrow_dark,
            function: () => openModal("sharing")
        },
        {
            name: "delete_history",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.delete),
            title: t("settings.delete_history"),
            danger: true,
            icon: icons.right_arrow_dark,
            function: () => openModal("delete_history")

        },
        {
            name: "delete_device",
            valid: openDevice && (FLAVOR === "bike" ? true : openDevice.visibleswitch?.editdevice.delete),
            title: t("settings.delete_device"),
            danger: true,
            icon: icons.right_arrow_dark,
            function: () => openModal("delete_device")

        },
    ]

    const validOptions = () => {
        return options.filter(option => option.valid)
    }

    const validInfo = () => {
        return info.filter(info => info.valid)
    }

    const handleInvite = (accept, id) => {
        request.post("", "device/share/acceptdenyinvitation", {deviceid: [id], accept: accept}).then((response) => {
            if(response.data.successful){
                getDevices()
            }else{
                alert("error")
            }
        })
    }

    const alarmCentralInfo = [
        {
            name: "central",
            valid: true,
            title: t("settings.alarm_central"),
            data: openDevice?.modelspecificinfo?.alarmcenter?.centername ?? "",
            icon: icons.dots_vertical,
            function: () => openAlarmModal("central")
        },
        {
            name: "info",
            valid: openDevice?.modelspecificinfo?.alarmcenter?.centerid !== null,
            title: t("settings.additional_info"),
            data: null,
            icon: icons.dots_vertical,
            function: () => openAlarmModal("info")
        },
        {
            name: "test",
            valid: openDevice?.modelspecificinfo?.alarmcenter?.centerid !== null,
            title: t("settings.installation_test"),
            data: fixDate(openDevice?.modelspecificinfo?.alarmcenter?.installationtest, true) ?? "",
            icon: icons.dots_vertical,
            function: () => openAlarmModal("installation_test")
        },
        {
            name: "unlink",
            valid: openDevice?.modelspecificinfo?.alarmcenter?.centerid !== null,
            title: t("settings.unlink_alarm"),
            danger: true,
            icon: icons.right_arrow_dark,
            function: () => openAlarmModal("unlink_central")

        },
    ]

    const validAlarmCentral = () => {
        return alarmCentralInfo.filter(option => option.valid)
    }    

    return(
        <>
        {!openDevice
            ?
            <>
            <div className="d-flex flex-column">
            <div>
                <Searchbar submitSearch={(data) => filterSearch(data)}/>   
                <p className="thin mt-3 mb-1">{t("settings.my_devices")}</p>
                <div className="d-flex flex-column">
                    <div className={`scrollable-y shadow-small settings_device_list primary`}>
                    {myDevices && myDevices.map((device, index) => (
                        <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < myDevices.length-1 ? "border-settings" : ""} cursor-pointer`} onClick={() => setOpenDevice(device)}>
                            <div className="d-flex justify-content-between align-items-center">
                                <span className="regular">{device.name}</span>
                                <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                            </div>
                        </div>
                    ))}
                    </div>
                    {myDevices && myDevices?.length === 0 && !loading &&
                        <div className={`p-3 w-100 d-flex flex-column bg-transparent`}>
                           <span className="regular">{t("settings.no_devices")}</span>
                        </div>
                    }
                </div>
            </div>
            <p className="thin mt-3 mb-1">{t("settings.shared_devices")}</p>
            <div className="d-flex flex-column">
                <div className={`scrollable-y shadow-small settings_device_list primary`}>
                {loading &&
                    <div className={`p-3 w-100 d-flex flex-column placeholder-glow`} >
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular placeholder w-50"></span>
                        </div>
                    </div>
                }
                {sharedDevices && sharedDevices.map((device, index) => (
                    <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < sharedDevices.length-1 ? "border-settings" : ""} cursor-pointer`} onClick={() => setOpenDevice(device)}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular">{device.name}</span>
                            <img className="size_15" src={icons.right_arrow_dark} alt={""} />
                        </div>
                    </div>
                ))}
                </div>
                {sharedDevices && sharedDevices.length === 0 && !loading &&
                    <div className={`p-3 w-100 d-flex flex-column bg-transparent`}>
                        <span className="regular">{t("settings.no_devices")}</span>
                    </div>
                }
            </div>
            <p className={`thin mt-3 mb-1 ${pendingInvites?.length<1 ? "d-none" : ""}`}>{t("settings.device_invitations")}</p>
            <div className="d-flex flex-column">
                <div className={`scrollable-y shadow-small settings_device_list primary`}>
                {pendingInvites && pendingInvites.map((device, index) => (
                    <div key={index} className={`p-3 w-100 d-flex flex-column bg-white ${index < pendingInvites?.length-1 ? "border-settings" : ""}`}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="regular">{t("settings.invitation_sub", {from: device.sharedstatus.sharedfrom, device: device.name})}</span>
                            <div>
                                <img className="size_15 me-3 cursor-pointer" src={icons.check} alt={""} onClick={() => handleInvite(true, device.id)}/>
                                <img className="size_15 cursor-pointer" src={icons.cross} alt={""} onClick={() => handleInvite(false, device.id)}/>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            <p className="thin mt-3 mb-1">{t("settings.add_share")}</p>         
            <div className="d-flex flex-column shadow-small settings-radius bg-white">
                <div className="p-3 d-flex justify-content-between align-items-center border-settings cursor-pointer" onClick={() => setAddDevice(!addDevice)}>
                    <span className="regular">{t("settings.add_device")}</span>
                    <img className="size_15" src={icons.plus_grey} alt={""} />
                </div>
                <div className="p-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setShareDevice(!shareDevice)}>
                    <span className="regular">{t("settings.share_device")}</span>
                    <img className="size_15" src={icons.share_grey} alt={""} />
                </div>
            </div>
        </div>
        <AddDevice show={addDevice} onClose={() => setAddDevice(!addDevice)} updateDevices={() => getDevices()} />
        <ShareDevice show={shareDevice} updateDevices={() => getDevices()} devices={myDevicesFull} onClose={() => setShareDevice(!shareDevice)} />
        </>
        : !alarmCentralShow 
        ?
        <>
            <div className="d-flex flex-column mb-5">
                {!MOBILE && <Button btnClass="btn-left-section" className="align-self-start" icon={icons.back} notext={true} onClick={() => setOpenDevice()}/>}
                <h3 className="my-2">{openDevice.name}</h3>
                <p className="thin mb-1 ">{t("settings.edit_device")}</p>
                <div className="d-flex flex-column shadow-small settings-radius">
                    {validOptions().map((item, index) => (
                        <div key={index} className={`p-3 d-flex justify-content-between bg-white ${index < validOptions().length-1 ? "border-settings" : ""}`}>
                            <span className={`regular ${item.danger ? "text-danger" : ""}`}>{item.title}</span>
                            <div className={`d-flex align-items-center ${item.icon ? "cursor-pointer" : ""}`} onClick={item.function}>
                                {item.img ? <img className="size_25" src={item.data} alt=""/> : <p className="bold">{item.data}</p>}
                                {item.icon &&
                                    <img className="ms-3 size_15" src={item.icon} alt={""} />
                                }
                            </div>
                        </div>
                    ))}
                </div>
                {validInfo().length !== 0 &&
                <>
                <p className="thin mb-1 mt-3">{t("settings.device_info")}</p>
                <div className="d-flex flex-column shadow-small settings-radius">
                    {validInfo().map((item, index) => (
                        <div key={index} className={`p-3 d-flex justify-content-between bg-white ${index < validInfo().length-1 ? "border-settings" : ""}`}>
                            <span className={`regular ${item.danger ? "text-danger" : ""}`}>{item.title}</span>
                            <div className={`d-flex align-items-center`}>
                                <p className="bold">{item.data}</p>
                            </div>
                        </div>
                    ))}
                </div>
                </>
                }

            </div>
            <DeviceModal show={showModal}  centrals={centrals} deleteData={(data) => deleteData(data)} removeShared={(pending, id, email) => removeShared(pending, id, email)} onSave={(data, reset) => saveData(data, reset)} type={type} data={openDevice} onClose={() => setShowModal(!showModal)}/>
        </>
        :




        <>
        <div className="d-flex flex-column mb-5">
            {!MOBILE && <Button btnClass="btn-left-section" className="align-self-start" icon={icons.back} notext={true} onClick={() => setAlarmCentralShow(false)}/>}
            <h3 className="my-2">{t("settings.alarm_central")}</h3>
            <p className="thin mb-1 ">{openDevice.name}</p>
            <div className="d-flex flex-column shadow-small settings-radius">
                {validAlarmCentral().map((item, index) => (
                <div key={index} className={`p-3 d-flex justify-content-between bg-white ${index < validAlarmCentral().length-1 ? "border-settings" : ""}`}>
                    <span className={`regular ${item.danger ? "text-danger" : ""}`}>{item.title}</span>
                    <div className={`d-flex align-items-center cursor-pointer`} onClick={item.function}>
                        <p className="bold">{item.data}</p>
                        <img className="ms-3 size_15" src={icons.dots_vertical} alt={""} />  
                    </div>
                </div> 
                ))}
   
            </div>
            {openDevice?.modelspecificinfo?.alarmcenter?.centerid !== null &&
            <>
                <p className="thin mb-1 mt-3">{t("settings.downloads")}</p>
                <div className="d-flex flex-column shadow-small settings-radius">
                    <div className={`p-3 d-flex justify-content-between bg-white border-settings`}>
                        <span className={`regular`}>{t("settings.alarm_central_contract")}</span>
                        <div className={`d-flex align-items-center cursor-pointer`} onClick={() => downloadPDF(openDevice?.modelspecificinfo?.alarmcenter?.centercontracturl)}>
                            <img className="ms-3 size_15" src={icons.download} alt={""} />  
                        </div>
                    </div>
                    <div className={`p-3 d-flex justify-content-between bg-white`}>
                        <span className={`regular`}>{t("settings.installation_certificate")}</span>
                        <div className={`d-flex align-items-center cursor-pointer`} onClick={() => downloadCertificate()}>
                            <img className="ms-3 size_15" src={icons.download} alt={""} />  
                        </div>
                    </div>    
                </div>  
            </>
            }
        </div>
        <AlarmCentralModal show={showAlarmModal} postTest={() => getTestInfo(true)} sendTestAlarm={() => sendTestAlarm()} centrals={centrals} testInfo={testInfo} deleteData={(data) => deleteData(data)} removeShared={(pending, id, email) => removeShared(pending, id, email)} onSave={(data, reset) => saveDataAlarm(data, reset)} type={alarmType} data={openDevice} onClose={() => setShowAlarmModal(!showAlarmModal)}/>
        </>




        }
       
        </>
    )
}

export default Devices;