import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, FLAVOR } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import { fixDate, staticSort, translateLanguages } from '../../helpers/helperFunctions';
import Button from '../Buttons/Button';
import DevicePill from './DevicePill';
import { useIsMobile } from '../../helpers/customHooks';
import toast from 'react-hot-toast';

const Notifications = (props) => {
	const { t } = useTranslation();
    const MOBILE = useIsMobile();
    const [notificationSettings, setNotificationSettings] = useState()
    const [sameForAll, setSameForAll] = useState(true)
    const [notificationDevice, setNotificationDevice] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [selectedDevice, setSelectedDevice] = useState()
    const [initialLoad, setInitialLoad] = useState(false)
    const [visibleToggles, setVisibleToggles] = useState({})
    const [notiArray, setNotiArray] = useState([])
    const [toggles, setToggles] = useState(
        //SÄTTA TOGGLES TRUE FALSE etc anvädns för att toggla all också
        FLAVOR === "alco"
        ?
        {
            ignitionOff: false,
            lowBattery: false,
            powerCut: false,
            deviceOverspeed: false,
            bypass: false,
            invalidBreathSample: false,
            testPassed: false,
            vibration: false,
            alco_testFailed: false,
            retest_passed: false
        }
        :
        FLAVOR === "bike"
        ?
        {

        }
        :
        {
            deviceOnline: false,
            deviceOffline: false,
            ignitionOn: false,
            ignitionOff: false,
            geofenceEnter: false,
            geofenceExit: false,
            tampering: false,
            lowBattery: false,
            lowExternalBattery: false,
            vibration: false,
            powerCut: false,
            deviceOverspeed: false,
            temperature: false,
            humidity: false,
            light: false,
            externalpower: false
        }
    )

    const toggleMap = {
        device_online: "deviceOnline",
        device_offline: "deviceOffline",
        ignition_on: "ignitionOn",
        ignition_off: "ignitionOff",
        geofence_enter: "geofenceEnter",
        geofence_exit: "geofenceExit",
        tampering: "tampering",
        low_battery: "lowBattery",
        low_external_battery: "lowExternalBattery",
        vibration: "vibration",
        power_cut: "powerCut",
        device_overspeed: "deviceOverspeed",
        temperature: "temperature",
        humidity: "humidity",
        bypass: "alco_physical_bypass",
        invalid_breath_sample: "alco_invalid_breath_sample",
        light: "light",
        externalpower: "externalpower",
        test_failed: "alco_testFailed",
        retest_passed: "alco_retest_passed",
        test_passed: "alco_test_passed"
    }
    useEffect(() => {
        getNotifications().then(() => {
            // Once notifications are fetched, allow future updates to trigger the API call
            setInitialLoad(true);
        });
    }, [])

    const getNotifications = async () => {
        request.get("notification/settings").then((response) => {
            if(response?.data){
                setNotificationSettings(response.data[0].notificationssettings)
            }
        })    
    }

    

    useEffect(() => {
        if(notificationSettings){
            setSameForAll(notificationSettings.sameforalldevices)
            if(!notificationSettings.sameforalldevices){
                let sorted = staticSort(notificationSettings.notificationdevice, "devicename")
                setNotificationDevice(sorted)
            }else{
                setVisibleToggles(notificationSettings.visibleswitch)
                Object.entries(notificationSettings.notification).forEach(([key, value]) => {
                    toggleNotification(key, value)
                });
            }
        }
    }, [notificationSettings])

    useEffect(() => {
        selectDevice(selectedIndex)
    }, [notificationDevice])



    const selectDevice = (index) => {
        if(!notificationDevice[index]){
            return
        }else{
            setSelectedIndex(index);
        }
    };

    useEffect(() => {
        if(notificationDevice.length > 0){
            setSelectedDevice(notificationDevice[selectedIndex]);
            Object.entries(notificationDevice[selectedIndex].notifications).forEach(([key, value]) => {
                toggleNotification(key, value)
            });
            setVisibleToggles(notificationDevice[selectedIndex].visibleswitch)
        }

    }, [selectedIndex, notificationDevice])


    useEffect(() => {
        // Convert the toggleMap to a format where it can be easily looked up by the `visibleToggles` keys
        const reverseToggleMap = Object.entries(toggleMap).reduce((acc, [key, value]) => {
            acc[value] = key; // Reverse the mapping
            return acc;
        }, {});
    
        const array = Object.entries(visibleToggles)
            .filter(([key, value]) => value && reverseToggleMap[key]) // Check if the toggle is true and exists in the reverse map
            .map(([key]) => reverseToggleMap[key]); // Map to the string value in the original toggleMap
    
        if(FLAVOR === "bike"){
            let array = ["ignition_on", "ignition_off", "geofence_enter", "geofence_exit", "low_battery", "vibration", "device_overspeed"]
            let sorted = array.sort()
            setNotiArray(sorted)
        }else if(FLAVOR === "alco"){
            let sorted = array.sort()            
            setNotiArray(sorted);    
        }else{
            let sorted = array.sort()
            setNotiArray(sorted);    
        }
    }, [visibleToggles]);


    const toggleNotification = (type, value) => {
        setToggles(prevToggles => ({
            ...prevToggles,
            [type]: value
        }));
        updateNoti(type, value)
    }

    useEffect(() => {
        if(notificationSettings && sameForAll !== notificationSettings.sameforalldevices){
            let data = {
                sameforalldevices: sameForAll
            }
            request.post("", "notification/settingsupdate", data).then((response) => {
                if(response.data.successful){
                    toast.success(t("toast.success"))
                }else{
                    toast.error(t("toast.error"))
                }
                getNotifications()

            });
        }
    }, [sameForAll])


    const updateNoti = (type, value) => {
        if(notificationSettings.sameforalldevices && notificationSettings.notification.hasOwnProperty(type)){
            if (notificationSettings.notification[type] !== value) {
                let data = {
                    sameforalldevices: sameForAll,
                    notifications: [[type, value]]
                }
                request.post("", "notification/settingsupdate", data).then((response) => {
                    if(response.data){
                        if(response.data.successful){
                            toast.success(t("toast.success"))
                        }else{
                            toast.error(response.data.message)
                        }
                        getNotifications()
                    }else{
                        toast.error(t("toast.error"))
                    }
                });
            }
        }else{
            let specific = notificationSettings.notificationdevice[selectedIndex]
            if(specific && specific.notifications.hasOwnProperty(type)){
                if(specific.notifications[type] !== value){
                    let data = {
                        deviceid: specific.deviceid,
                        sameforalldevices: sameForAll,
                        notifications: [[type, value]]
                    }
                    request.post("", "notification/settingsupdate", data).then((response) => {
                        if(response.data){
                            toast.success(t("toast.success"))
                            getNotifications()
                        }else{
                            toast.error(t("toast.error"))
                        }
                    });
                }
            }
        }
    }

    const toggleAll = () => {
        setToggles(currentToggles => {
            const newToggles = { ...currentToggles };
            Object.keys(currentToggles).forEach(key => {
                // Only toggle if the key is visible
                
                if (visibleToggles[key]) {
                    newToggles[key] = allChecked ? false : true;
                }
            });
            return newToggles;
        });
        // Since you're changing the notification settings, you might want to update them server-side as well
        updateNotiAll();
    }
    

    const updateNotiAll = () => {
        let allArray = []
        Object.keys(toggles).forEach((type) => {
            if (visibleToggles[type]) { // Only include if toggle is visible
                let inner = [type, allChecked ? false : true];
                allArray.push(inner);
            }
        });
        if(notificationSettings.sameforalldevices){
            let data = {
                sameforalldevices: sameForAll,
                notifications: allArray
            }
            request.post("", "notification/settingsupdate", data).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getNotifications()
                }else{
                    toast.error(t("toast.error"))
                }
            });
        }else{
            let specific = notificationSettings.notificationdevice[selectedIndex]
            let data = {
                deviceid: specific.deviceid,
                sameforalldevices: sameForAll,
                notifications: allArray
            }
            request.post("", "notification/settingsupdate", data).then((response) => {
                if(response.data){
                    toast.success(t("toast.success"))
                    getNotifications()
                }else{
                    toast.error(t("toast.error"))
                }
            });
        }
    }


    function areRelevantTogglesTrue(allToggles, visibleToggles) {
        // Get keys from visibleToggles where the value is true
        const relevantKeys = Object.keys(visibleToggles).filter(key => visibleToggles[key] === true);
        
        // Check if all those keys have true values in allToggles
        const allRelevantAreTrue = relevantKeys.every(key => allToggles[key] === true);
        return allRelevantAreTrue;
      }

    const allChecked = areRelevantTogglesTrue(toggles, visibleToggles);


    return(
        <div className="mb-5">
            <p className="thin mb-1">{t("settings.notifications")}</p>
            {/* SAME FOR ALL */}
            <div className={`${notiArray.length === 0 ? "d-none" : ""} d-flex flex-column shadow-small settings-radius primary`}>
                <div className={`p-3 d-flex justify-content-between bg-white align-items-center`}>
                    <span className="body">{t("notifications.notifications_per_device")}</span>
                    <div className="form-check form-switch">
                        <input className="form-check-input" onChange={() => setSameForAll(!sameForAll)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={!sameForAll} />
                    </div>
                </div>
            </div>
            <div className={`${notificationDevice?.length === 0 ? "d-none" : ""}d-flex align-items-end justify-content-end mt-3`}>
                {!sameForAll &&
                    <select
                        onChange={(event) => selectDevice(event.target.value)}
                        value={selectedIndex}
                        className={`form-control bg-grey ${MOBILE ? "w-50" : "w-25"}`}
                    >
                    {notificationDevice.map((device, index) => {
                        return(
                            <option key={index} value={index}>{device.devicename}</option>
                            )
                    })}
                    </select>
                }
            </div>

            {/*ACTIVATE ALL*/}
            <div className={`${notiArray.length === 0 ? "d-none" : ""} d-flex flex-column shadow-small settings-radius mt-3 primary`}>
                <div className={`p-3 d-flex justify-content-between bg-white align-items-center`}>
                    <span className="body">{t("notifications.activate_all")}</span>
                    <div className="form-check form-switch">
                        <input 
                            className="form-check-input" 
                            onChange={() => toggleAll()} 
                            type="checkbox" 
                            role="switch" 
                            id="flexSwitchCheckDefault" 
                            checked={allChecked}
                        />
                    </div>
                </div> 
            </div>
            {notiArray.length === 0 &&
                <h3 className="regular text-center mt-5">{t("notifications.no_notification_settings_available")}</h3>
            }
            <div className="d-flex flex-column shadow-small settings-radius mt-3 primary">
                {notiArray.map((item, index) => {
                    const toggleKey = toggleMap[item]
                    const toggleState = toggles[toggleKey] || false                    
                    return (
                        <div key={index} className={`p-3 d-flex justify-content-between bg-white align-items-center ${index < notiArray.length-1 ? "border-settings" : ""}`}>
                            <span className="body">{t(`notifications.${item}`)}</span>
                            <div className="form-check form-switch">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    onChange={() => toggleNotification(toggleKey, !toggleState)} 
                                    role="switch" 
                                    id="flexSwitchCheckDefault" 
                                    checked={toggleState} />
                            </div>
                        </div>  
                    )
                })}
            </div>
        </div>
    )
}

export default Notifications;