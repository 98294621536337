import React, { useState, useEffect } from 'react';
import 'dayjs/locale/sv';
import { useIsMobile } from '../../helpers/customHooks';
import { FLAVOR } from '../../helpers/constants';

const DeviceInfoCard = (props) => {
    const MOBILE = useIsMobile();
    const margin = (MOBILE && (props.index+1) % 3 === 0) || (!MOBILE && (props.index+1) % 4 === 0) ? "0" : "3"
    return(
        <>
            <div className={`col-${MOBILE ? "4" : "3"} m-0 p-0 pe-3 pb-${margin}`}>
                <div onClick={props.spec ? (e) => props.onClick(e) : null} className={`${props.spec ? "cursor-pointer" : ""} position-relative card border-12 border-0 d-flex flex-column deviceInfoCard justify-content-center align-items-center`}>
                    <img src={props.icon} alt="" className={`${FLAVOR === "alco" ? "d-none" : ""} size_15 position-absolute top-0 end-0 me-1 mt-1 primary-icon ${props.name === "internal_battery" ? "" : ""}`}/>
                    <h4 className='primary'>{props.value}</h4>
                    <p className="thin dark_grey text-truncate">{props.label}</p>
                </div>
            </div>
        </>
    )
}

export default DeviceInfoCard;