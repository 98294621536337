import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons, MAIL_FORMATTING } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';


const MassShareModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [email, setEmail] = useState("")
    const [addedEmails, setAddedEmails] = useState([])
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])

    useEffect(() => {
        setShow(props.show)  
        if(!props.show){
            setAddedEmails([])
        }
    },[props.show])

    const handleChange = (type, e) => {
        switch(type){
            case "email":
                setEmail(e.target.value)
            break;
            default: return
        }
        setInvalidEmail(false)
    }

    const handleClose = () => {
        props.onClose()

      //  setShow(false)
    }
    

    useEffect(() => {
        if(props.selectedDevices){
            let array = props.selectedDevices.map(device => device.deviceid)
            setSelectedDevices(array)
        }else{
            setSelectedDevices([props.device.deviceid])
        }
    }, [props.selectedDevices])
    

    const addEmails = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("events.add_emails")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="px-3 pt-3 pb-5">
                <p className="thin dark-grey text-center pb-3">{t("events.add_emails_sub", {device: props.device.devicename})}</p>
                <form>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"
                            value={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                if(!MAIL_FORMATTING.test(e.target.value) || addedEmails.includes((e.target.value))){
                                        setInvalidEmail(true)
                                }else{
                                    const newAddedEmails = [...addedEmails, e.target.value];
                                    setAddedEmails(newAddedEmails); // Update the state with the new array
                                    setEmail("")
                                }
                                e.preventDefault();// Prevent the default Enter key behavior
                                
                                }
                            }}
                        />
                        <img className={`position-absolute end-0 top-0 mt-3 me-2 ${!invalidEmail ? "d-none" : ""}`} src={icons.cross} alt=""/>
                        <div className="d-flex flex-wrap mt-3">
                            {addedEmails.map((email, index) => (
                                <p key={index} className="thin dark-grey me-3 mb-1">{email}</p>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.addEmail({deviceid: selectedDevices, email: addedEmails})} btnClass={`${addedEmails?.length === 0 ? "disabled" : ""} btn-primary`}>{t("events.add")}</Button>
            </div>
            </>
        )
    }

    const addEmailsMass = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.user_settings} alt=""/>
                    <h3 className="ms-2">{t("events.add_emails")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="px-3 pt-3 pb-5">
                <p className="thin dark-grey text-center pb-3">{t("events.add_emails_mass_sub1")}</p>
                <div className="d-flex flex-column mb-3">
                    {props.selectedDevices.map((device, index) => (
                        <p key={index} className="thin dark-grey text-start">{device.devicename}</p>
                    ))}
                </div>
                <form>
                    <div className="form-floating form-floating-group flex-grow-1 is-valid">
                        <input
                            type="text"
                            className="form-control bg-light-grey p-2"
                            id="name"
                            value={email}
                            onChange={(e) => {
                                handleChange("email", e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                if(!MAIL_FORMATTING.test(e.target.value) || addedEmails.includes((e.target.value))){
                                    setInvalidEmail(true)
                                }else{
                                    const newAddedEmails = [...addedEmails, e.target.value];
                                    setAddedEmails(newAddedEmails); // Update the state with the new array
                                    setEmail("")
                                }
                                e.preventDefault();// Prevent the default Enter key behavior
                                
                                }
                            }}
                        />
                        <img className={`position-absolute end-0 top-0 mt-3 me-2 ${!invalidEmail ? "d-none" : ""}`} src={icons.cross} alt=""/>
                        <div className="d-flex flex-wrap mt-3">
                            {addedEmails.map((email, index) => (
                                <p key={index} className="thin dark-grey me-3 mb-1">{email}</p>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("settings.cancel")}</Button>
               <Button onClick={() => props.addEmail({deviceid: selectedDevices, email: addedEmails})} btnClass={`${addedEmails?.length === 0 ? "disabled" : ""} btn-primary`}>{t("events.add")}</Button>
            </div>
            </>
        )
    }

    const typeRender = () => {
        switch(props.type){
            case "add": return addEmails()
            case "mass_share": return addEmailsMass()
            default: return null
        }
    }

    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default MassShareModal;