
// export const MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 786;

export const FLAVOR = process.env.REACT_APP_FLAVOR
export const CUST = process.env.REACT_APP_ASSETS
export const API_URL = process.env.REACT_APP_API_URL
export const INDEX_URL = process.env.REACT_APP_EXTENDED_URL;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_MAP_API_KEY
export const STRIPE_TEST_KEY = process.env.REACT_APP_STRIPE_TEST_KEY;
export const MAIL_FORMATTING = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const icons = {
    battery: {
        battery_0: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_0.svg`).default,
        battery_25: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_25.svg`).default,
        battery_50: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_50.svg`).default,
        battery_75: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_75.svg`).default,
        battery_100: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_100.svg`).default,
        battery_charge: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery/battery_charge.svg`).default,

    },
    reset_arrow:  require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/reset_arrow.svg`).default,
    priority: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/priority.svg`).default,
    mass_share: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/mass_share.svg`).default,
    bookmark: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/bookmark.svg`).default,
    bookmark_filled: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/bookmark_filled.svg`).default,

    in_app: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/in_app.svg`).default,
    map_icon_title: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_icon_title.svg`).default,
    battery_settings: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_settings.svg`).default,
    sharing: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sharing.svg`).default,
    trashcan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/trashcan.svg`).default,
    user_settings: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/user_settings.svg`).default,
    power_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/power_on.svg`).default,
    power_off: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/power_off.svg`).default,
    receipt: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/receipt.svg`).default,
    distance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/distance.svg`).default,
    volt: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/volt.svg`).default,

    geofence: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/geofence.svg`).default,
    polygon: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/polygon.svg`).default,
    pen: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/pen.svg`).default,

    envelope: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/envelope.svg`).default,
    envelope_gradient: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/envelope_gradient.svg`).default,

    
    central: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/central.svg`).default,
    humidity: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/humidity.svg`).default,
    ignition: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/ignition.svg`).default,
    relay: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/relay.svg`).default,
    temperature: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/temperature.svg`).default,

    dark_map: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/dark_map.svg`).default,
    hybrid_map: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/hybrid_map.svg`).default,
    light_map: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/light_map.svg`).default,
    satellite_map: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/satellite_map.svg`).default,
    terrain_map: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/terrain_map.svg`).default,

    cluster_markers: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/cluster_markers.svg`).default,
    hide_offline: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/hide_offline.svg`).default,
    show_geofences: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/show_geofences.svg`).default,
    show_groups: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/show_groups.svg`).default,
    show_name: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/show_name.svg`).default,
    trace_unit: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/trace_unit.svg`).default,
    show_alarms: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/map_settings/show_alarms.svg`).default,

    stopped_marker: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/stopped_marker.svg`).default,

    hamburger: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/hamburger.svg`).default,
    stoplight_green: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/stoplight_green.svg`).default,
    stoplight_yellow: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/stoplight_yellow.svg`).default,
    stoplight_red: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/stoplight_red.svg`).default,
    ssf: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/ssf.svg`).default,
    radio_checked: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/radio_checked.svg`).default,
    radio_unchecked: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/radio_unchecked.svg`).default,
    sidebar: {
        device_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/device_active.svg`).default,
        device_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/device_inactive.svg`).default,
        bell_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/bell_active.svg`).default,
        bell_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/bell_inactive.svg`).default,
        geofence_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/geofence_active.svg`).default,
        geofence_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/geofence_inactive.svg`).default,
        history_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/history_active.svg`).default,
        history_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/history_inactive.svg`).default,
        statistics_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/statistics_active.svg`).default,
        statistics_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/statistics_inactive.svg`).default,
        card_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/card_active.svg`).default,
        card_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/card_inactive.svg`).default,
        settings_active: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/settings_active.svg`).default,
        settings_inactive: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sidebar/settings_inactive.svg`).default,
    },
    expand: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/expand_arrow.svg`).default,
    search: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/search.svg`).default,
    sort_button: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sort_button.svg`).default,
    close_gray: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/close_gray.svg`).default,
    plus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/plus.svg`).default,
    plus_grey: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/plus_grey.svg`).default,
    signal_green: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/signal_green.svg`).default,
    signal_red: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/signal_red.svg`).default,
    signal_grey: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/signal_grey.svg`).default,
    online_icon: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/online_icon.svg`).default,
    offline_icon: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/offline_icon.svg`).default,
    info_icon: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/info_icon.svg`).default,
    dot_selected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/dot_selected.svg`).default,
    dot_unselected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/dot_unselected.svg`).default,
    cog: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/cog.svg`).default,
    group_select: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/group_select.svg`).default,
    group: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/group.svg`).default,
    check: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/check.svg`).default,
    check_empty: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/check_empty.svg`).default,
    check_empty_light: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/check_empty_light.svg`).default,
    sort_arrow: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/sort_arrow.svg`).default,
    next_arrow_btn: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/next_arrow_btn.svg`).default,
    back: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/back.svg`).default,
    location_marker_online: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/location_marker_online.svg`).default,
    location_marker_offline: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/location_marker_offline.svg`).default,
    circle_selected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/circle_selected.svg`).default,
    circle_unselected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/circle_unselected.svg`).default,
    poly_selected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/poly_selected.svg`).default,
    poly_unselected: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/poly_unselected.svg`).default,
    right_arrow_light: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/right_arrow_light.svg`).default,
    right_arrow_dark: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/right_arrow_dark.svg`).default,
    dots_vertical: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/dots_vertical.svg`).default,
    share_grey: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/share_grey.svg`).default,
    down_arrow_dark: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/down_arrow_dark.svg`).default,
    download: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/download.svg`).default,
    user: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/user.svg`).default,
    clock: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/clock.svg`).default,
    tachometer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/tachometer.svg`).default,
    pin: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/pin.svg`).default,
    battery_empty: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_empty.svg`).default,
    battery_full: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_full.svg`).default,
    battery_half: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_half.svg`).default,
    battery_quarter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_quarter.svg`).default,
    battery_three_quarter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/battery_three_quarter.svg`).default,
    marker: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/marker.svg`).default,
    cross: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/cross.svg`).default,

    event_map_icons: {
        central: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/central_map.svg`).default,
        charge_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/charge_on_map.svg`).default,
        hum_below: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/hum_below_map.svg`).default,
        hum_exceed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/hum_exceed_map.svg`).default,
        light_off: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/light_off_map.svg`).default,
        light_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/light_on_map.svg`).default,
        sleep: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/sleep_map.svg`).default,
        temp_below: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/temp_below_map.svg`).default,
        temp_exceed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/temp_exceed_map.svg`).default,
        wakeup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/wakeup_map.svg`).default,

        moving: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/moving.svg`).default,
        stopped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/stopped.svg`).default,
        online: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/online.svg`).default,
        offline: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/offline.svg`).default,
        geo_exit: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/geo_exit.svg`).default,
        geo_enter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/geo_enter.svg`).default,
        tampering: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/tampering.svg`).default,
        low_battery: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/low_battery.svg`).default,
        low_external_battery: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/low_external_battery.svg`).default,
        ignition_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/ignition_on.svg`).default,
        ignition_off: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/ignition_off.svg`).default,
        vibration: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/vibration.svg`).default,
        power_cut: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/power_cut.svg`).default,
        sos: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/sos.svg`).default,
        overspeed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/overspeed.svg`).default,
        unknown: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/unknown.svg`).default,
        bypass: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/map/bypass.svg`).default,    
    },
    event_icons: {
        central: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/central.svg`).default,
        charge_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/charge_on.svg`).default,
        hum_below: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/hum_below.svg`).default,
        hum_exceed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/hum_exceed.svg`).default,
        light_off: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/light_off.svg`).default,
        light_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/light_on.svg`).default,
        sleep: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/sleep.svg`).default,
        temp_below: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/temp_below.svg`).default,
        temp_exceed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/temp_exceed.svg`).default,
        wakeup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/wakeup.svg`).default,

        moving: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/moving.svg`).default,
        stopped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/stopped.svg`).default,
        online: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/online.svg`).default,
        offline: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/offline.svg`).default,
        geo_exit: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/geo_exit.svg`).default,
        geo_enter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/geo_enter.svg`).default,
        tampering: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/tampering.svg`).default,
        low_battery: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/low_battery.svg`).default,
        low_external_battery: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/low_external_battery.svg`).default,
        ignition_on: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/ignition_on.svg`).default,
        ignition_off: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/ignition_off.svg`).default,
        vibration: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/vibration.svg`).default,
        power_cut: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/power_cut.svg`).default,
        sos: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/sos.svg`).default,
        overspeed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/overspeed.svg`).default,
        unknown: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/unknown.svg`).default,
        bypass: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/bypass.svg`).default,
        breath_test_failed: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/breath_test_failed.svg`).default,
        breath_test_success: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/icons/events/breath_test_success.svg`).default
    },
}

export const markers = {
    history_start: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/history_start.svg`).default,
    history_stop: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/history_stop.svg`).default,

    cluster_marker: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/cluster_marker.svg`).default,
    device_online: FLAVOR === "bike" ? require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/bike/online.png`) : require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/default.png`),
    device_offline: FLAVOR === "bike" ? require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/bike/offline.png`) : require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/default.png`),

    blue: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/blue/van.png`)
    },
    dark: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/dark/van.png`)
    },
    green: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/green/van.png`)
    },
    grey: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/grey/van.png`)
    },
    light: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/light/van.png`)
    },
    orange: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/orange/van.png`)
    },
    simple: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/airplane.svg`).default,
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/ambulance.svg`).default,
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/bicycle.svg`).default,
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/boat.svg`).default,
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/bus.svg`).default,
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/car.svg`).default,
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/caravan.svg`).default,
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/container.svg`).default,
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/default.svg`).default,
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/electriccar.svg`).default,
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/airplane.svg`).default,
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/excavator.svg`).default,
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/forklift.svg`).default,
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/helicopter.svg`).default,
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/jet.svg`).default,
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/monstertruck.svg`).default,
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/moped.svg`).default,
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/motorcycle.svg`).default,
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/pickup.svg`).default,
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/rv.svg`).default,
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/sailboat.svg`).default,
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/snowmobile.svg`).default,
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/taxi.svg`).default,
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/tractor.svg`).default,
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/truck.svg`).default,
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/truckcontainer.svg`).default,
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/simple/van.svg`).default,
    },
    yellow: {
        airplane: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/airplane.png`),
        ambulance: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/ambulance.png`),
        bicycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/bicycle.png`),
        boat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/boat.png`),
        bus: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/bus.png`),
        car: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/car.png`),
        caravan: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/caravan.png`),
        container: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/container.png`),
        default: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/default.png`),
        electriccar: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/electriccar.png`),
        engine: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/airplane.png`),
        excavator: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/excavator.png`),
        forklift: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/forklift.png`),
        helicopter: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/helicopter.png`),
        jet: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/jet.png`),
        monstertruck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/monstertruck.png`),
        moped: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/moped.png`),
        motorcycle: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/motorcycle.png`),
        pickup: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/pickup.png`),
        rv: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/rv.png`),
        sailboat: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/sailboat.png`),
        snowmobile: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/snowmobile.png`),
        taxi: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/taxi.png`),
        tractor: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/tractor.png`),
        truck: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/truck.png`),
        truckcontainer: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/truckcontainer.png`),
        van: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/markers/yellow/van.png`)
    }
}
export const logos = {
    small: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/logos/small.svg`).default,
    big: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/logos/big.svg`).default,

}
export const images = {
    add_device_1: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/images/add_device_1_2.svg`).default,
    add_device_2: require(`../assets/customer/${process.env.REACT_APP_ASSETS}/images/add_device_2.svg`).default,
}

export const pricing = require(`../components/Subscription/Stripe/Pricing/${process.env.REACT_APP_ASSETS}/pricelist.json`);
    
