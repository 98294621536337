import React, { useState, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import { SidebarData } from "../Sidebar/SidebarData";
import { useLocation, useNavigate } from 'react-router-dom'
import { INDEX_URL, CUST, icons, logos, FLAVOR } from "../../helpers/constants";
import LanguagePicker from '../LanguagePicker';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';
import * as request from "../../services/FetchService";
import InAppModal from '../Modals/InAppModal';

const SidebarMobile = (props) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [showBurger, setShowBurger] = useState(false)
	const [newAlarm, setNewAlarm] = useState(false)
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		getNewMessage()
	}, [])
	const getNewMessage = () => {
		request.post("","notification/newmessagealarm").then((response) => {
			if(response.data){
				setNewAlarm(response.data.newalarms)
			}
		});
	}

	useEffect(() => {
		if(location.pathname === "/events"){
			setNewAlarm(false)
		}
	}, [location.pathname])



	const setTitle = () => {
		let icon;
		let text;
		switch(location.pathname){
			case "/devices":
				icon = icons.device_active
				text = t("sidebar.my_devices")
				break;
			case "/events":
				icon = icons.bell_active
				text = t("sidebar.alarms")
				break;
			case "/geofence":
				icon = icons.geofence_active
				text = t("sidebar.geofence")
				break;
			case "/history":
				icon = icons.history_active
				text = t("sidebar.history")
				break;
			case "/subscriptions":
				icon = icons.card_active
				text = t("sidebar.subscription")
				break;
			case "/settings":
				icon = icons.settings_active
				text = t("sidebar.settings")
				break;

			default: return;
		}

		return (
			<div className="d-flex">
				<img className="me-2"src={icon} alt=""/>
				<h4>{text}</h4>
			</div>
		)
	}


	useEffect(() => {
		// const info = document.querySelector(".section-left_bottom_data");

		// const infoExpanded = document.querySelector(".section-left_bottom_data_expanded_burger");
		// if(showBurger && info){
		// 	info.classList.replace("section-left_bottom_data", "section-left_bottom_data_expanded_burger");
		// }else if(!showBurger && infoExpanded){
		// 	infoExpanded.classList.replace("section-left_bottom_data_expanded_burger", "section-left_bottom_data");
		// }
    }, [showBurger])

	const handleModal = () => {
		setShowBurger(false)
		setShowModal(true)
	}

	return(
		<>
		<div className="w-100 py-3 position-absolute glossy topbar d-flex align-items-center justify-content-between shadow-small">
			<img src={icons.hamburger} alt={""} className=" size_25 ms-3 cursor-pointer nav-logo" onClick={() => setShowBurger(!showBurger)}/>
			<img src={logos.big} alt="" className="sidebar-mobile-logo" />
			<img src={logos.small} alt={""} className="size_25 me-3 opacity-0"/>

			{/* <img src={icons.hamburger} alt={""} className="byrger size_25 ms-3 pt-1 cursor-pointer nav-logo" onClick={() => setShowBurger(!showBurger)}/>
			<div className='mx-5 behind position-absolute top-0 w-75'>{setTitle()}</div> */}
		</div>
		<Collapse id="byrger" in={showBurger} orientation={'horizontal'} timeout={500}>
			<div className="hamburger-container byrger">
				<img src={icons.close_gray} alt={""} className="byrger size_25 ms-3 mt-3 cursor-pointer nav-logo" onClick={() => setShowBurger(!showBurger)}/>
				<div className="d-flex p-4 h-100">
					<div className="d-flex flex-column mt-5">
						{SidebarData.map((item, index) => (
							<NavLink key={index} className="position-relative d-flex justify-content-start mt-3 mb-3 nav-item-mobile" to={item.path} onClick={() => setShowBurger(false)}>
								<img
									src={item.icon_inactive}
									// src={location.pathname === item.path ? item.icon_active : item.icon_inactive}
									className="cursor-pointer"
									alt="..."
								/>
								{item.path === "/events" && newAlarm &&
									<span className="position-absolute dot bg-offline top-0 mt-0 start-0 ms-3"/>
								}
								<h4 className={`ms-3 ${location.pathname === item.path ? "text-white" : "text-white"}`}>{t(item.title)}</h4>
							</NavLink>
						))}
						{FLAVOR !== "alco" &&
						<div className="position-relative d-flex justify-content-start mt-3 mb-3 nav-item-mobile" onClick={() => handleModal()}>
							<img src={icons.envelope} className="cursor-pointer white-icon size_20" alt="..."/>
							<h4 className={`ms-3 text-white`}>{t("sidebar.messages")}</h4>
						</div>
							}
						<div className={`position-absolute left-0 bottom-0 ms-0 mb-2 bg-white cursor-pointer rounded`}>
							<LanguagePicker />				
						</div>
					</div>
				</div>
			</div>
		</Collapse>
		<InAppModal show={showModal} onClose={() => setShowModal(!showModal)}/>

		</>
	)
}

export default SidebarMobile;