import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons } from "../../helpers/constants";
import { Card, Collapse, Dialog } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { staticSort } from '../../helpers/helperFunctions';

const Searchbar = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [searchKey, setSearchKey] = useState("");
    const [showSort, setShowSort] = useState(false);
    const [pageSpecific, setPageSpecific] = useState(location.pathname)
    const [devices, setDevices] = useState(props.devices)

    const [eventTypes, setEventTypes]= useState([
        { "type": "deviceOnline", "name": "events_raw.deviceOnline", "selected": false },
        { "type": "deviceOffline", "name": "events_raw.deviceOffline", "selected": false },
        { "type": "deviceOverspeed", "name": "events_raw.deviceOverspeed", "selected": false },
        { "type": "deviceStopped", "name": "events_raw.deviceStopped", "selected": false },
        { "type": "deviceMoving", "name": "events_raw.deviceMoving", "selected": false },
        { "type": "geofenceEnter", "name": "events_raw.geofenceEnter", "selected": false },
        { "type": "geofenceExit", "name": "events_raw.geofenceExit", "selected": false },
        { "type": "alarmcenter", "name": "events_raw.alarmcenter", "selected": false },
        { "type": "psmsafetyzone", "name": "events_raw.psmsafetyzone", "selected": false },
        { "type": "ignitionOn", "name": "events_raw.ignitionOn", "selected": false },
        { "type": "ignitionOff", "name": "events_raw.ignitionOff", "selected": false },
        { "type": "vibration", "name": "events_raw.vibration", "selected": false },
        { "type": "tampering", "name": "events_raw.tampering", "selected": false },
        { "type": "powerCut", "name": "events_raw.powerCut", "selected": false },
        { "type": "lowBattery", "name": "events_raw.lowBattery", "selected": false },
        { "type": "powerOn", "name": "events_raw.powerOn", "selected": false },
        { "type": "powerOff", "name": "events_raw.powerOff", "selected": false },
        { "type": "deviceChargeOn", "name": "events_raw.chargeOn", "selected": false },
        { "type": "deviceChargeOff", "name": "events_raw.chargeOff", "selected": false },
        { "type": "deviceWake", "name": "events_raw.wakeup", "selected": false },
        { "type": "deviceSleepOn", "name": "events_raw.deviceSleepOn", "selected": false },
        { "type": "deviceLightOn", "name": "events_raw.deviceLightOn", "selected": false },
        { "type": "deviceLightOff", "name": "events_raw.deviceLightOff", "selected": false },
        { "type": "deviceTempExceeded", "name": "events_raw.deviceTempExceeded", "selected": false },
        { "type": "deviceTempBelow", "name": "events_raw.deviceTempBelow", "selected": false },
        { "type": "deviceHumExceeded", "name": "events_raw.deviceHumExceeded", "selected": false },
        { "type": "deviceHumBelow", "name": "events_raw.deviceHumBelow", "selected": false },
        { "type" : "bypass", "name": "events_raw.bypass", "selected": false},
        { "type" : "retest_failed", "name": "events_raw.retest_failed", "selected": false},
        { "type" : "retest_not_delivered", "name": "events_raw.retest_not_delivered", "selected": false},
        { "type" : "retest_passed", "name": "events_raw.retest_paseed", "selected": false},
        { "type" : "retest_requested", "name": "events_raw.retest_requested", "selected": false},
        { "type" : "test_failed", "name": "events_raw.test_failed", "selected": false},
        { "type" : "test_passed", "name": "events_raw.test_passed", "selected": false},
        { "type" : "temporary_override_activated", "name": "events_raw.temporary_override_activated", "selected": false},
        { "type" : "temporary_override_ended", "name": "events_raw.temporary_override_ended", "selected": false},
        { "type" : "engine_unblocked", "name": "events_raw.engine_unblocked", "selected": false},
        { "type" : "engine_blocked", "name": "events_raw.engine_blocked", "selected": false}

      ])

    const getVisibleEvents = () => {
        return eventTypes.filter(eventType => props.visibleEvents?.[eventType.type]);

    }

	const [filter, setFilter] = useState({
		type: pageSpecific === "/devices" ? "last_update" : "date",
		order: pageSpecific === "/devices" ? true : false
	})



    const handleChange = (field, e) => {
		setSearchKey(e.target.value);
    };

	const onKeyDown = (e) => {
		const { key } = e;
		if (key === "Enter" && searchKey.length > 0) {
			props.submitSearch(searchKey)
            clearSelected()
		}
	}


	useEffect(() => {
		if(searchKey.length == 0){
			if(props.submitSearch){
				props.submitSearch("")
			}
		}
	}, [searchKey])
    
	const openSort = () => {
		setShowSort(!showSort)
	}

	const updateFilter = (type) => {
		setFilter((prevFilter) => {
			// If the type is changing, reset order to true
			if (prevFilter.type !== type) {
				return { type: type, order: true };
			}
			// If the type is the same, toggle the order but keep the type
			return { ...prevFilter, order: !prevFilter.order };
		});
        props.updateOrder(filter)
	}

    const updateEventFilter = (type) => {
        // Create a new array with updated `selected` status
        const updatedEventTypes = eventTypes.map(eventType => 
            eventType.type === type 
                ? { ...eventType, selected: !eventType.selected } // Toggle `selected` for the matching type
                : eventType // Leave other types unchanged
        );
    
        // Update `props.updateTypes` with the new array if it's available
        if (props.updateTypes) {
            props.updateTypes(updatedEventTypes);
            setEventTypes(updatedEventTypes);
            setSearchKey("")
        }
        
        // Update local state
    };

    const updateDeviceFilter = (id) => {
        // Create a new array with updated `selected` status
        const filtering = devices.map(device => 
            device.id === id 
                ? { ...device, selected: !device.selected } // Toggle `selected` for the matching device
                : device // Leave other devices unchanged
        );
    
        // Call `props.updateDevices` with the updated array if it's available
        if (props.updateDevices) {
            props.updateDevices(filtering);
            setDevices(filtering)
            setSearchKey("")
        }
    };

    const clearSelected = () => {
        if(devices){
            setDevices((prevState) => 
                prevState.map(device => ({
                    ...device,          // Spread the existing properties of the device
                    selected: false     // Override the `selected` property to be false
                }))
            );  
        }
        if(eventTypes){
            setEventTypes((prevState) =>
                prevState.map(event => ({
                    ...event,
                    selected: false
                }))
            );
        }
    }

    // useEffect(() => {
    //     if(props.updateDevices){
    //         props.updateDevices(devices)
    //     }        
    // }, [devices])


    useEffect(() => {
        let sorted = staticSort(props.devices, "name")
        setDevices(sorted)        
    }, [props.devices])


    useEffect(() => {
        setSearchKey("")
        clearSelected()
        
    }, [props.clearSearch])



    
    

    const renderCardEvents = () => (
        <Card className={`py-0 px-1 mt-1 mb-2 sortCollapse`}>
        <div className="d-flex flex-row justify-content-between px-1 pt-3 border-bottom">
            <h4 className="mb-2">{t("search.sort_by_date")}</h4>
            <img
                className="size_20 right_7 cursor-pointer close_img"
                src={icons.close_gray}
                onClick={() =>setShowSort(!showSort)}
                alt="..."
            />
        </div>
        <ul className="list-group list-group-flush">
            <li
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                onClick={() => {
                    updateFilter("date")
                // changeSortOrder("name")
                }}
            >
            <p className={`my-2 ${filter.type === "date" ? "text-navy" : "text-gray"}`}>
                {t("search.date")}
                <img src={icons.sort_arrow} className={`ms-2 ${filter.type !== "date" ? "d-none" : ""} ${filter.order && "rotate-180"}`} alt={""}/>
            </p>
            </li>
        </ul>
        <div className="d-flex flex-row justify-content-between px-1 pt-3 border-bottom">
            <h4 className="mb-2">{t("search.sort_type")}</h4>
        </div>
        <ul className="list-group list-group-flush sort-event-list">
            {getVisibleEvents().map((eventtype, index) => (
            <li
                key={index}
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                onClick={() => {
                    updateEventFilter(eventtype.type)
                // changeSortOrder("name")
                }}
            >
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <p className={`my-2 ${eventtype.selected ? "text-navy bold" : "text-gray"}`}>{t(eventtype.name)}</p>
                    {eventtype.selected &&
                    <img src={icons.check} className="size_15 me-3" alt=""/>
                    }
                </div>
            </li>
            ))}
        </ul>

        <div className="d-flex flex-row justify-content-between px-1 pt-3 border-bottom">
            <h4 className="mb-2">{t("search.sort_device")}</h4>
        </div>
        <ul className="list-group list-group-flush sort-event-list">
            {devices?.map((device, index) => (
            <li
                key={index}
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center"
                onClick={() => {
                    updateDeviceFilter(device.id)
                // changeSortOrder("name")
                }}
            >
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <p className={`my-2 ${device.selected ? "text-navy bold" : "text-gray"}`}>{t(device.name)}</p>
                    {device.selected &&
                    <img src={icons.check} className="size_15 me-3" alt=""/>
                    }
                </div>
            </li>
            ))}
        </ul>
    </Card>
    )

    const renderCardDevices = () => (
        <Card className={`py-0 px-1 mt-1 mb-2 shadow-medium`}>
            <div className="d-flex flex-row justify-content-between align-items-center px-2 pt-3 pb-2 border-bottom">
                <h4 className="mb-2 primary">{t("search.sort_device")}</h4>
                <img
                    className="mb-2 size_25 cursor-pointer close_img"
                    src={icons.close_gray}
                    onClick={() =>setShowSort(!showSort)}
                    alt="..."
                />
            </div>
        <ul className="list-group list-group-flush">
            <li
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center px-2"
                onClick={() => {
                    updateFilter("name")
                // changeSortOrder("name")
                }}
            >
            {/* <p className={`my-2 ${sortState.sortOrder != "" && sortState.sort === "name" ? "text-navy" : "text-gray"
              }`}
            > */}
            <p className={`my-2 primary ${filter.type === "name" ? "primary bold" : "dark_grey"}`}>
				{t("search.name")}
				<img src={icons.sort_arrow} className={`ms-2 size_15 primary-icon ${filter.type !== "name" ? "d-none" : ""} ${filter.order && "rotate-180"}`} alt={""}/>
            </p>
            </li>
            <li
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center px-2"
                onClick={() => {
					updateFilter("status")
                // changeSortOrder("name")
                }}
            >
            <p className={`my-2 primary ${filter.type === "status" ? "primary bold" : "dark_grey"}`}>
                {t("search.status")}
				<img src={icons.sort_arrow} className={`ms-2 size_15 primary-icon ${filter.type !== "status" ? "d-none" : ""} ${filter.order && "rotate-180"}`} alt={""}/>
            </p>
            </li>
			<li
                className="cursor-pointer list-group-item d-flex justify-content-between align-items-center px-2"
                onClick={() => {
					updateFilter("last_update")
                // changeSortOrder("name")
                }}
            >
            <p className={`my-2 primary ${filter.type === "last_update" ? "primary bold" : "dark_grey"}`}>
                {t("search.last_update")}
				<img src={icons.sort_arrow} className={`ms-2 size_15 primary-icon ${filter.type !== "last_update" ? "d-none" : ""} ${filter.order && "rotate-180"}`} alt={""}/>
            </p>
            </li>
        </ul>
        </Card>
    )
    
    return(
        <>
        <div className={`d-flex flex-column ${props.className ? props.className : ""}`}>
            <div className={"p-2 search_bar position-relative"}>
                <img
                src={icons.search}
                className="cursor-pointer"
                onClick={() => props.submitSearch(searchKey)}
                alt="..."
                />
                <input
                    autoComplete="off"
                    type="text"
                    className={`form-control input-search p ${props.error && !props.isLiveStreamDeviceSearch ? "is-invalid" : ""}`}
                    id="newName"
                    value={searchKey}
                    onKeyDown={onKeyDown}
                    onChange={(e) => {
                        handleChange("SearchKey", e);
                    }}
                    placeholder={t("search.search")}
                />
                {props.sort &&
                <img
                    src={icons.sort_button}
                    className="cursor-pointer search_bar_sort_icon"
                    onClick={() => openSort()}
                    alt="..."
                />
                }

            <Collapse in={showSort} className="position-absolute top-100 w-100" style={{zIndex: "9999"}}>
                {pageSpecific == "/devices"
                    ? renderCardDevices()
                    : pageSpecific == "/events"
                    ? renderCardEvents()
                    : null
                }

            </Collapse> 
			</div>

        </div>
        </>
    )
}

export default Searchbar;