import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Collapse } from '@mui/material';
import { icons } from '../../helpers/constants';
import { fixDate } from '../../helpers/helperFunctions';
import GroupRow from '../Groups/GroupRow';
import DeviceSelectionRow from './DeviceSelectionRow';
import Button from '../Buttons/Button';
import MassShareModal from '../Modals/MassShareModal';
import * as request from "../../services/FetchService";
import toast from 'react-hot-toast';


const MassShareDeviceRow = (props) => {
    const { t } = useTranslation();
    const [visibleDetail, setVisibleDetail] = useState(false)
    const [toggleActive, setToggleActive] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const [emails, setEmails] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState("")

    useEffect(() => {
        setEmails(props.device.shared.sharedwith)
    },[props.device])
    

    const toggleDevice = (event, id) => {
        event.stopPropagation();
        props.setDeviceToggle(id)
    }

    useEffect(() => {
		if(allSelected && emails.length > 0){
			let updatedDevices = [...emails];
            updatedDevices.map((device, index) => {
				device.selected = true
            })
			setEmails(updatedDevices)
		}else{
            if(emails.length>0){
                let updatedDevices = [...emails];
                updatedDevices.map((device, index) => {
                    device.selected = false
                })
                setEmails(updatedDevices)
            }
		}
    }, [allSelected])

    useEffect(() => {
        if(emails.length > 0){
            let updatedDevices = [...emails];
            updatedDevices.map((device, index) => {
                device.selected = false
            })
            setEmails(updatedDevices)
        } 
    }, [visibleDetail])

    useEffect(() => {
        if(!allSelected && emails.length > 0){
            let updatedDevices = [...emails];
            updatedDevices.map((device, index) => {
                device.selected = false
            })
            setEmails(updatedDevices)
        }
    }, [allSelected])

    const selectEmail = (index) => {
        let updatedDevices = [...emails];
        if (index !== -1) {
            var device = updatedDevices[index];
            if (device.selected !== true){
                device.selected = true;
            }else{
                device.selected = false;
            } 
            updatedDevices[index] = device;
            setEmails(updatedDevices)
          }
    }

    const toggleModal = (type) => {
        setType(type)
        setShowModal(!showModal)
    }

    const deleteShared = (array) => {
        let selected = array.filter(email => email.selected).map(email => email.email)
         let data = {
            deviceid: [props.device.deviceid],
            email: selected
         }
        request.post("", "events/share/delete", data).then((response) => {
            toast.success(t("toast.done"))
            props.getDevices()
        });
    }

    const renderDeviceDetail = () => {
        let array = props.device.shared.sharedwith
        return(
            <div className="p-4 d-flex flex-column">
                {array.length > 0 
                    ?
                    <>
                        <p className="mb-3">{t("events.shared_with")}:</p>
                        <div className=" group_device_list scrollable-y shadow-small w-100">
                            <DeviceSelectionRow 
                                text={t("events.choose_all")}
                                selected={array.some(email => !email.selected) ? false : true}
                                onClick={() => setAllSelected(!allSelected)}
                            />
                            {array.map((email, index) => (
                                <DeviceSelectionRow
                                    key={index}
                                    text={email.email}
                                    index={index}
                                    selected={email.selected ? true : false}
                                    onClick={() => selectEmail(index)}
                                />
                            ))}
                        </div>
                        <div className="d-flex flex-row justify-content-end">
                            <Button btnClass={"btn-primary"} className="mt-3" onClick={() => toggleModal("add")}>{t("events.add_emails")}</Button>
                            <Button btnClass={"btn-danger"} onClick={() => deleteShared(array)} className={`${!array.some(email => email.selected) ? "disabled": ""} ms-3 mt-3`}>{t("events.remove_shared")}</Button>   
                        </div>
               
                    </>
                    :
                    <>
                        <p>{t("events.not_shared")}</p>
                        <div className="d-flex flex-row justify-content-end">
                            <Button btnClass={"btn-primary"} className="mt-3" onClick={() => toggleModal("add")}>{t("events.add_emails")}</Button>
                        </div>
                    </>

                }
                

            </div>
        )
    }

    useEffect(() => {
        setToggleActive(props.device.selected)
    }, [props.device.selected])


    const handleAdd = async (data) => {
        let result = await props.addEmail(data)
        if(result === "SUCCESS"){
            toast.success(t("toast.success"))
            setShowModal(false)
        }
    }
    return(
        <>
            <div className={`row m-0 cursor-pointer ps-3 py-3 ${visibleDetail ? "bg-primary" : props.index % 2 === 0 ? "bg-white" : "bg-light-grey"}`} onClick={() => setVisibleDetail(visibleDetail === props?.device?.deviceid ? null : props?.device?.deviceid)}>
                <div className="col-5 m-0 p-0 text-truncate d-flex align-items-center">
                    <div className={`me-3 ${!props.checkboxVisibility ? "d-none" : toggleActive ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`} onClick={(event) => toggleDevice(event, props?.device)}>
                        {toggleActive && <img className="white-icon" src={icons.check} alt={""} />}
                    </div>
                    <p className="bold text-truncate">{props.device.devicename}</p>
                </div>
                <div className="col-4 m-0 p-0">
                    <p>{props.device.uniqueid}</p>
                </div>
                <div className="col-2 m-0 p-0">
                    <p>{props.device.shared.shared ? t("events.yes") : t("events.no")}</p>
                </div>
                <div className="col-1 m-0 p-0">
                    <p>{props.device.shared.amount}</p>
                </div>

            </div>
            <Collapse in={visibleDetail === props.device.deviceid}>
                {renderDeviceDetail()}
            </Collapse>
            <MassShareModal addEmail={(data) => handleAdd(data)} device={props.device} show={showModal} onClose={() => setShowModal(false)} type={type} />
        </>
    )
}

export default MassShareDeviceRow;