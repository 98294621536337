import React, { useState, useEffect } from 'react';
import { INDEX_URL, CUST, icons, FLAVOR } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import * as request from "../../services/FetchService";
import AccountModal from '../Modals/AccountModal';
import { useNavigate } from 'react-router-dom';
import { fixDate } from '../../helpers/helperFunctions';
import toast from 'react-hot-toast';

const Account = (props) => {
	const { t } = useTranslation();
    const [accountInfo, setAccountInfo] = useState()
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        getAccountInfo()
    }, [])

    const getAccountInfo = () => {
        request.get("account/info").then((response) => {
            let data = FLAVOR === "bike" || FLAVOR === "alco" ? response.data.response : response.data
            setAccountInfo(data)
        })
        
    }

    const openModal = (type) => {
        setType(type)
        setShowModal(!showModal)
    }

    const saveData = (data) => {
        if(data instanceof File){
            request.put("account/picture/upload", data).then((response) => {
                if(response.data.successful){
                    let imgurl = response.data.imageurl
                    let data2 = { imageurl: imgurl }
                    request.post("", "account/update", data2).then((response2) => {
                        if(response2.data.successful){
                            setShowModal(!showModal)
                            getAccountInfo()
                            toast.success(t("toast.saved"));
                        }else{
                            toast.error(t("toast.failed"));
                        }
            
                    })
                }
            })
            return
        }
        request.post("", "account/update", data).then((response) => {
            if(response.data.successful){
                setShowModal(!showModal)
                getAccountInfo()
                toast.success(t("toast.saved"));
            }else{
                toast.error(t("toast.failed"));
            }

        })
    }

    const deleteAccount = () => {
        let userid = accountInfo.userid
        request.post("", "account/delete", {userid: userid}).then((response) => {
            if(response.data.error){
                toast.error(response.data.api_message)
            }else{
                localStorage.clear()
                navigate("/devices")
            }

        })
    }

    const items = FLAVOR === "bike" ? ["username", "email", "password", "gender", "age", "weight", "delete"] : ["username", "email", "password", "delete"]


    return(
        <>
        <p className="mb-1 thin">{t("settings.my_account")}</p>
        {FLAVOR === "bike" &&
        <div className="userimage mx-auto position-relative mb-3">
            <img src={accountInfo && accountInfo?.userimage} alt="" className="w-100" />
            <div className="editimg position-absolute bottom-0 end-0 d-flex justify-content-center align-items-center mb-2 cursor-pointer" onClick={() => openModal("img")} >
                <img className="white-icon" src={icons.pen} alt=""/>
            </div>
        </div>
        }
        <div className="d-flex flex-column shadow-small settings-radius">
            {items.map((item, index) =>(
                <div 
                    key={index} 
                    className={`p-3 d-flex justify-content-between bg-white ${index < items.length-1 ? "border-settings" : ""}`}>
                    {
                        item === "username"
                        ? <span className="body primary">{t("settings.name")}</span>
                        : item === "email"
                        ? <span className="body primary">{t("settings.email")}</span>
                        : item === "password"
                        ? <span className="body primary">{t("settings.password")}</span>
                        : item === "gender"
                        ? <span className="body primary">{t("settings.gender")}</span>
                        : item === "age"
                        ? <span className="body primary">{t("settings.birthday")}</span>
                        : item === "weight"
                        ? <span className="body primary">{t("settings.weight")}</span>
                        : <span className="body primary text-danger">{t("settings.delete_account")}</span>
                    }
                    <div className="cursor-pointer d-flex align-items-center" onClick={() => openModal(item)}>
                        {
                            item === "username"
                            ? <p className="primary bold">{accountInfo && accountInfo.username}</p>
                            : item === "email"
                            ? <p className="primary bold">{accountInfo && accountInfo.email}</p>
                            : item === "password"
                            ? <p className="primary bold">{"******"}</p>
                            : item === "gender"
                            ?  <p className="primary bold">{accountInfo && accountInfo.gender ? t(`settings.${[accountInfo.gender]}`) : ""}</p>
                            : item === "age"
                            ?  <p className="primary bold">{accountInfo && fixDate(accountInfo.birthday, true)}</p>
                            : item === "weight"
                            ?  <p className="primary bold">{accountInfo && accountInfo.weight} {accountInfo?.weight ? "kg" : ""}</p>
                            : ""
                        }
                        <img className="ms-3 size_15" src={item === "delete" ? icons.right_arrow_dark : icons.dots_vertical} alt={""} />
                    </div>
                </div>
            ))}
        </div>
        <AccountModal show={showModal} type={type} data={accountInfo} deleteAccount={() => deleteAccount()} onSave={(data) => saveData(data)} onClose={() => setShowModal(!showModal)} />
        </>
    )
}

export default Account;