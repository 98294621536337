import React, { useState, useEffect } from "react";

import { Dialog, Card } from "@mui/material";
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from "../Buttons/Button";
import { useIsMobile } from "../../helpers/customHooks";

const BillingModal = (props) => {
	const MOBILE = useIsMobile();
	const { t } = useTranslation();
	const [show, setShow] = useState(props.show);
	const [errorText, setErrorText] = useState("");
	const [email, setEmail] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [selectedVatType, setSelectedVatType] = useState("");
	const [vatnr, setVatnr] = useState("");
	const languages = [
		{
			label: "Arabic",
			value: "ar",
		},
		{
			label: "Bulgarian",
			value: "bg",
		},
		{
			label: "Chinese (Simplified)",
			value: "zh",
		},
		{
			label: "Croatian",
			value: "hr",
		},
		{
			label: "Filipino",
			value: "fil",
		},
		{
			label: "Czech",
			value: "cs",
		},
		{
			label: "Danish",
			value: "da",
		},
		{
			label: "Dutch",
			value: "nl",
		},
		{
			label: t("language.english"),
			value: "en",
		},
		{
			label: "Estonian",
			value: "et",
		},
		{
			label: "Finnish",
			value: "fi",
		},
		{
			label: "French",
			value: "fr",
		},
		{
			label: "Georgian",
			value: "ka",
		},
		{
			label: "German",
			value: "de",
		},
		{
			label: "Greek",
			value: "el",
		},
		{
			label: "Hebrew",
			value: "he",
		},
		{
			label: "Hungarian",
			value: "hu",
		},
		{
			label: "Icelandic",
			value: "is",
		},
		{
			label: "Indonesian",
			value: "id",
		},
		{
			label: "Italian",
			value: "it",
		},
		{
			label: "Japanese",
			value: "ja",
		},
		{
			label: "Korean",
			value: "ko",
		},
		{
			label: "Latvian",
			value: "lv",
		},
		{
			label: "Lithuanian",
			value: "lt",
		},
		{
			label: "Macedonian",
			value: "mk",
		},
		{
			label: "Malay",
			value: "ms",
		},
		{
			label: "Maltese",
			value: "mt",
		},
		{
			label: "Polish",
			value: "pl",
		},
		{
			label: "Portuguese",
			value: "pt",
		},
		{
			label: "Romanian",
			value: "ro",
		},
		{
			label: "Russian",
			value: "ru",
		},
		{
			label: "Slovak",
			value: "sk",
		},
		{
			label: "Slovenian",
			value: "sl",
		},
		{
			label: "Spanish",
			value: "es",
		},
		{
			label: t("language.swedish"),
			value: "sv",
		},
		{
			label: "Thai",
			value: "th",
		},
		{
			label: "Tibetan",
			value: "bo",
		},
		{
			label: "Turkish",
			value: "tr",
		},
		{
			label: "Vietnamese",
			value: "vi",
		},
		{
			label: "Norwegian Bokmål",
			value: "nb",
		},
	];

	const vatTypes = [
		{ label: "Australia", value: "au_abn" },
		{ label: "Austria", value: "eu_vat" },
		{ label: "Belgium", value: "eu_vat" },
		{ label: "Bulgaria", value: "eu_vat" },
		{ label: "Chile", value: "cl_tin" },
		{ label: "Croatia", value: "eu_vat" },
		{ label: "Cyprus", value: "eu_vat" },
		{ label: "CzechRepublic", value: "eu_vat" },
		{ label: "Denmark", value: "eu_vat" },
		{ label: "Estonia", value: "eu_vat" },
		{ label: "Finland", value: "eu_vat" },
		{ label: "France", value: "eu_vat" },
		{ label: "Georgia", value: "ge_vat" },
		{ label: "Germany", value: "eu_vat" },
		{ label: "Greece", value: "eu_vat" },
		{ label: "HongKong", value: "hk_br" },
		{ label: "Hungary", value: "eu_vat" },
		{ label: "Iceland", value: "is_vat" },
		{ label: "India", value: "in_gst" },
		{ label: "Indonesia", value: "id_npwp" },
		{ label: "Ireland", value: "eu_vat" },
		{ label: "Israel", value: "il_vat" },
		{ label: "Italy", value: "eu_vat" },
		{ label: "Latvia", value: "eu_vat" },
		{ label: "Liechtenstein", value: "li_uid" },
		{ label: "Lithuania", value: "eu_vat" },
		{ label: "Luxembourg", value: "eu_vat" },
		{ label: "Malta", value: "eu_vat" },
		{ label: "Mexico", value: "mx_rfc" },
		{ label: "Netherlands", value: "eu_vat" },
		{ label: "NewZealand", value: "nz_gst" },
		{ label: "Norway", value: "no_vat" },
		{ label: "Poland", value: "eu_vat" },
		{ label: "Portugal", value: "eu_vat" },
		{ label: "Romania", value: "eu_vat" },
		{ label: "SaudiArabia", value: "sa_vat" },
		{ label: "Slovakia", value: "eu_vat" },
		{ label: "Slovenia", value: "eu_vat" },
		{ label: "SouthAfrica", value: "za_vat" },
		{ label: "SouthKorea", value: "kr_brn" },
		{ label: "Spain", value: "eu_vat" },
		{ label: "Sweden", value: "eu_vat" },
		{ label: "Switzerland", value: "ch_vat" },
		{ label: "Taiwan", value: "tw_vat" },
		{ label: "Thailand", value: "th_vat" },
		{ label: "Ukraine", value: "ua_vat" },
		{ label: "UnitedArabEmirates", value: "ae_trn" },
		{ label: "UnitedKingdom", value: "gb_vat" },
		{ label: "UnitedStates", value: "us_ein" },
	];

	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	useEffect(() => {
		if (props.type) {
			setSelectedLanguage(props.data.receiptlanguage ? props.data.receiptlanguage : "");
			setEmail(props.data.email && props.data.email);
			setVatnr(props.data.vatnumber && props.data.vatnumber);
			setSelectedVatType("eu_vat");
		}
	}, [props]);

	const handleClose = () => {
		props.onClose();
		//  setShow(false)
	};

	const handleChange = (type, e) => {
		switch (type) {
			case "email":
				setEmail(e.target.value);
				break;
			case "vatnr":
				setVatnr(e.target.value);
				break;
			default:
				return;
		}
		setErrorText("");
	};

	const handleSave = (type) => {
		let data = {};
		switch (type) {
			case "email":
				data = { email: email };
				break;
			case "vatnr":
				data = { vatnumber: vatnr, vattype: selectedVatType };
				break;
			case "receipt_lang":
				data = { receiptlanguage: selectedLanguage };
				break;
			default:
				data = {};
		}
		props.onSave(data);
	};

	const emailContent = () => {
		return (
			<>
				<div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
					<div className="d-flex align-items-center">
						<img src={icons.receipt} alt="" />
						<h3 className="ms-2">{t("settings.change_email")}</h3>
					</div>
					<img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." />
				</div>
				<div className="p-3">
					<p className="thin dark-grey text-center pb-3">{t("settings.change_email_billing_sub")}</p>
					<form>
						<div className="form-group d-flex align-items-center">
							<input
								type="email"
								className="form-control bg-light-grey p-2"
								id="email"
								value={email}
								placeholder="email@email.com"
								onChange={(e) => {
									handleChange("email", e);
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										e.preventDefault(); // Prevent the default Enter key behavior
										handleSave(props.type);
									}
								}}
							/>
							<div className="invalid-feedback ms-1 bottom">{errorText === "" ? "" : errorText}</div>
						</div>
					</form>
				</div>
				<div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
					<Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">
						{t("settings.cancel")}
					</Button>
					<Button onClick={() => handleSave(props.type)} btnClass="btn-primary">
						{t("settings.save")}
					</Button>
				</div>
			</>
		);
	};

	const vatContent = () => {
		return (
			<>
				<div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
					<div className="d-flex align-items-center">
						<img src={icons.receipt} alt="" />
						<h3 className="ms-2">{t("settings.vat_nr")}</h3>
					</div>
					<img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." />
				</div>
				<div className="p-3">
					<p className="thin dark-grey text-center pb-3">{t("settings.change_vatnr_sub")}</p>
					<form>
						<p className="thin">{t("settings.vat_nr")}</p>
						<div className="form-group d-flex align-items-center">
							<input
								type="text"
								className="form-control bg-light-grey p-2"
								id="vatnr"
								value={vatnr}
								onChange={(e) => {
									handleChange("vatnr", e);
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										e.preventDefault(); // Prevent the default Enter key behavior
										handleSave(props.type);
									}
								}}
							/>
							<div className="invalid-feedback ms-1 bottom">{errorText === "" ? "" : errorText}</div>
						</div>
						<div className="mt-3 w-100">
							<p className="thin">{t("settings.vat_type")}</p>
							<select onChange={(event) => setSelectedVatType(event.target.value)} value={selectedVatType} className="form-control bg-light-grey p-2">
								<option value="">{t("settings.select")}</option>
								{vatTypes.map((lang, index) => {
									return (
										<option key={index} value={lang.value}>
											{lang.label}
										</option>
									);
								})}
							</select>
						</div>
					</form>
				</div>
				<div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
					<Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">
						{t("settings.cancel")}
					</Button>
					<Button onClick={() => handleSave(props.type)} btnClass="btn-primary">
						{t("settings.save")}
					</Button>
				</div>
			</>
		);
	};

	const receiptContent = () => {
		return (
			<>
				<div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
					<div className="d-flex align-items-center">
						<img src={icons.receipt} alt="" />
						<h3 className="ms-2">{t("settings.change_language")}</h3>
					</div>
					<img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." />
				</div>
				<div className="p-3 w-100">
					<p className="thin pb-3 text-center">{t("settings.receipt_language_sub")}</p>
					<select onChange={(event) => setSelectedLanguage(event.target.value)} value={selectedLanguage} className="form-control bg-light-grey p-2">
						<option value="">{t("settings.select")}</option>
						{languages.map((lang, index) => {
							return (
								<option key={index} value={lang.value}>
									{lang.label}
								</option>
							);
						})}
					</select>
				</div>
				<div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
					<Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">
						{t("settings.cancel")}
					</Button>
					<Button onClick={() => handleSave(props.type)} btnClass="btn-primary">
						{t("settings.save")}
					</Button>
				</div>
			</>
		);
	};

	const typeRender = () => {
		switch (props.type) {
			case "email":
				return emailContent();
			case "vatnr":
				return vatContent();
			case "receipt_lang":
				return receiptContent();
			default:
				return null;
		}
	};

	return (
		<>
			<Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
				<Card className="settings-dialog d-flex flex-column">
					<div className="w-100 h-100">{typeRender()}</div>
				</Card>
			</Dialog>
		</>
	);
};

export default BillingModal;
