import React, { useState, useEffect } from 'react';
import { icons } from "../../helpers/constants";
import { MapTogglesProvider, useMapToggles } from './ToggleContext'; // Adjust the path as necessary
import { useTranslation } from "react-i18next";
import Collapse from '@mui/material/Collapse'; // Import Collapse from MUI
import * as request from "../../services/FetchService";
import { useLocalStorage } from '../../helpers/customHooks';

const MapSettings = (props) => {
    const { mapToggles, toggleMode, mapTypes, toggleMap, setGroupArray } = useMapToggles();
    const [showSettings, setShowSettings] = useState(false)
    const [groups, setGroups] = useState()
    const [innerGroupArray, setInnerGroupArray] = useState([])
    const [storage, setStorage] = useLocalStorage("mapsettings")
    const { t } = useTranslation();

    useEffect(() => {
        toggleMap(storage ? storage.maptype : "light")
       // getGroups()
    }, [])

    const getGroups = () => {
        request.get("device/group/list").then((response) => {
            if(response.data){
                setGroups(response.data);
            }
		});
    }

    useEffect(() => {
        if(mapToggles[mapToggles.length-1].active){
            getGroups()
        }
    }, [mapToggles[mapToggles.length-1].active])

    const addGroup = (deviceArray) => {
        if (deviceArray.every(id => innerGroupArray.includes(id))) {
            // Remove all elements of deviceArray from innerGroupArray
            const updatedInnerGroupArray = innerGroupArray.filter(id => !deviceArray.includes(id));
            setInnerGroupArray(updatedInnerGroupArray);
        } else {
            // Add all elements of deviceArray to innerGroupArray
            setInnerGroupArray(prevInnerGroupArray => [...prevInnerGroupArray, ...deviceArray]);
        }
    }

    useEffect(() => {
        setGroupArray(innerGroupArray)
    }, [innerGroupArray])

    return (
        <>
            <div className={`map-setting-btn overmap cursor-pointer p-3 bg-white border-12 shadow-small`} onClick={() => !showSettings && setShowSettings(!showSettings)}>
                <div className="d-flex justify-content-between">
                    <div className='d-flex align-items-center'>
                        <img className="setting_icon" alt="Settings" src={icons.cog}/>
                        <div className={`${showSettings ? "ms-2 map-settings-popup-title-show" : "map-settings-popup-title"} align-self-center`}>
                            <h3 className="primary text-truncate">{t("map.map_settings")}</h3>
                        </div>
                    </div>
                    {showSettings &&
                        <img className={`cursor-pointer ${!showSettings ? "d-none" : ""}`} src={icons.close_gray} onClick={() => setShowSettings(!showSettings)} alt="..." /> 
                    }
                </div>
                <Collapse in={showSettings}> {/* Use Collapse component from MUI */}
                    <div className={`${showSettings ? "map-settings-popup-show cursor-pointer" : "map-settings-popup"}`}>
                        <div className="map-settings-window px-2 pb-2 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column mt-2">
                                <p className="align-self-start bold">{t("map.map_type")}</p>
                                {mapTypes.map((type, index) => (
                                    <div className="d-flex align-items-center justify-content-between mt-2" key={index} onClick={() => toggleMap(type.id)}>
                                        <p title={t(`map.${type.label}`)} className={`${type.active ? "bold" : ""}`}>{t(`map.${type.label}`)}</p>
                                        <img className={`ms-3 size_25 ${type.active ? "map_type_selected" : "map_type_not_selected"}`} alt={type.label} src={type.img}/>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex flex-column mt-2">
                                <p className="align-self-start bold">{t("map.toggles")}</p>
                                {mapToggles.map((type, index) => (
                                    type.visible &&
                                    <div className="d-flex align-items-center justify-content-between mt-2" key={index} onClick={() => toggleMode(type.label, !type.active)}>
                                        <p title={t(`map.${type.label}`)} className={`${type.active ? "bold" : ""}`}>{t(`map.${type.label}`)}</p>
                                        <div className={`ms-3 size_25 ${type.active ? "map_settings_selected" : "map_settings_not_selected"}`}>
                                            <img className={`size_25 ${type.active ? "white-icon p-1" : "p-1"}`} alt={type.label} src={type.img}/>
                                        </div>
                                    </div>  
                                ))}
                            </div>
                        </div>
                    </div>
                    {showSettings &&
                        <Collapse in={mapToggles[mapToggles.length-1].active}> {/* Use Collapse component from MUI */}
                            <div style={{maxHeight: "100px"}} className='scrollable-y mt-3'>
                                <p className="bold">{t("map.groups")}</p>
                                {groups && groups.map((group, index) => (
                                    <div key={index} className="mt-1 px-2 d-flex justify-content-between align-items-center" onClick={() => addGroup(group.deviceId)}>
                                        <p>{group.name}</p>
                                        <div className={`${group.deviceId.every(id => innerGroupArray.includes(id)) ? "bg-checked" : "bg-grey"} size_20 border-sm d-flex justify-content-center p-1`}>
                                            {group.deviceId.every(id => innerGroupArray.includes(id)) && <img className="white-icon" src={icons.check} alt={""} />}
                                        </div>
                                    </div>
                                ))}
                            </div>   
                        </Collapse>
                    }

                </Collapse>

            </div>
           
        </>
    )
}
export default MapSettings;