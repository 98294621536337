import React, { useState, useEffect, useRef } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import SegmentedControl from '../SegmentedControl/SegmentedControl';
import { fixDate } from '../../helpers/helperFunctions';


const InfoCardModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)

    useEffect(() => {
        setShow(props.show)
    },[props.show])

    
    const handleClose = () => {
        props.onClose()
      //  setShow(false)
    }

    const modelContent = () => {
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.sidebar.device_active} alt=""/>
                    <h3 className="ms-2">{t("devices.model")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="d-flex p-3 justify-content-around align-items-center">
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 me-2">
                    <p className="bold">{props?.data?.info}</p>
                    <p className="thin dark-grey">{t("devices.alcomodel")}</p>
                </div>
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 ms-2">
                    <p className="bold">{props?.data?.iot_model}</p>
                    <p className="thin dark-grey">{t("devices.iotmodel")}</p>
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("devices.close")}</Button>
            </div>
            </>
        )
    }

    const batteryContent = () => {
        console.log(props.data)
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.battery_settings} alt=""/>
                    <h3 className="ms-2">{t("devices.battery")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>
            <div className="d-flex p-3 justify-content-around align-items-center">
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 me-2">
                    <p className="bold">{props?.data?.value}%</p>
                    <p className="thin dark-grey">{t("devices.batterylevel")}</p>
                </div>
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 ms-2">
                    <p className="bold">{props?.data?.power_supply ? t("devices.on") : t("devices.off")}</p>
                    <p className="thin dark-grey">{t("devices.power_supply")}</p>
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("devices.close")}</Button>
            </div>
            </>
        )
    }

    const overrideContent = () => {
        console.log(props.data)
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("devices.override")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>

            <div className="d-flex p-3 justify-content-around align-items-center">
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 me-2">
                    <p className="bold">{props?.data?.active ? t("devices.yes") : t("devices.no")}</p>
                    <p className="thin dark-grey">{t("devices.active")}</p>
                </div>
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 ms-2">
                    <p className="bold">{props?.data?.current_until ? fixDate(props?.data?.current_until, true) : t("devices.no_expiration")}</p>
                    <p className="thin dark-grey">{t("devices.active_until")}</p>
                </div>
            </div>
            {props?.data?.pending &&
            <div className="d-flex p-3 pt-0 justify-content-around align-items-center">
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 me-2">
                    <p className="bold">{props?.data?.pending ? t("devices.yes") : t("devices.no")}</p>
                    <p className="thin dark-grey">{t("devices.pending_mode")}</p>
                </div>
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 ms-2">
                    <p className="bold">{props?.data?.pending_until ? fixDate(props?.data?.pending_until, true) : t("devices.no_expiration")}</p>
                    <p className="thin dark-grey">{t("devices.pending_date")}</p>
                </div>
            </div>
            }

            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("devices.close")}</Button>
            </div>
            </>
        )
    }

    const engineBlockContent = () => {
        console.log(props.data)
        return (
            <>
            <div className="d-flex flex-row p-3 justify-content-between border-settings align-items-center">
                <div className='d-flex align-items-center'>
                    <img src={icons.central} alt=""/>
                    <h3 className="ms-2">{t("devices.engine_block")}</h3>
                </div>
                <img className="cursor-pointer" src={icons.close_gray} onClick={() => props.onClose()} alt="..." /> 
            </div>

            <div className="d-flex p-3 justify-content-around align-items-center">
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 me-2">
                    <p className="bold">{props?.data?.active ? t("devices.on") : t("devices.off")}</p>
                    <p className="thin dark-grey">{t("devices.active")}</p>
                </div>
                <div className="detail_container d-flex flex-column border-12 p-3 align-items-center w-50 ms-2">
                    <p className="bold">{props?.data?.newmode ? t("devices.yes") : t("devices.no")}</p>
                    <p className="thin dark-grey">{t("devices.new_mode")}</p>
                </div>
            </div>
            <div className="d-flex flex-row p-3 justify-content-end border-settings-top align-items-center">
               <Button onClick={() => props.onClose()} btnClass="btn-secondary me-2">{t("devices.close")}</Button>
            </div>
            </>
        )
    }

    const typeRender = () => {
        switch(props?.data?.type){
            case "model": return modelContent()
            case "battery": return batteryContent()
            case "override": return overrideContent()
            case "engine_block": return engineBlockContent()
            default: return null
        }
    }

    return(
        <>
        <Dialog fullWidth={MOBILE ? true : false} open={show} onClose={handleClose}>
            <Card className="settings-dialog d-flex flex-column">
                <div className="w-100 h-100">
                    {typeRender()}
                </div>
            </Card>
        </Dialog>
        </>
    )
}

export default InfoCardModal;