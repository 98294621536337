import React, { useState, useEffect } from 'react';

import { Dialog, Card } from '@mui/material';
import { images, icons } from "../../helpers/constants";
import { useTranslation } from "react-i18next";
import Button from '../Buttons/Button';
import { useIsMobile } from '../../helpers/customHooks';
import * as request from "../../services/FetchService";
import MessageRow from '../Messages/MessageRow';
import OpenMessage from '../Messages/OpenMessage';
import SharedMessage from '../Messages/SharedMessage';


const InAppModal = (props) => {
    const MOBILE = useIsMobile();
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show)
    const [messages, setMessages] = useState([])
    const [selectedMessage, setSelectedMessage] = useState()
    const [sharedMessage, setSharedMessage] = useState()
    useEffect(() => {
        setShow(props.show)
        if(props.show){
            getMessages()
        }
    },[props.show])


    const handleClose = () => {
        setTimeout(() => {
            setSelectedMessage()
            setSharedMessage()     
        }, 250);
        props.onClose()
    }

    useEffect(() => {
        if(process.env.REACT_APP_FLAVOR !== "alco"){
            getMessages()
        }
    }, [])

    const getMessages = () => {
        setMessages()
        request.get("inappmessage/list").then((response) => {
            if(response.data.successful){
                let data = response.data.message
                data.reverse()
                setMessages(data)
            }
        });
    }

    const getSpecific = (id) => {
        if(selectedMessage && selectedMessage.messageid === id){
            return
        }
        request.post("", "inappmessage/info", {messageid: id}).then((response) => {
            if(response.data.successful){
                setSharedMessage()
                setSelectedMessage(response.data.message)
                if(response.data.message.isread === 0){
                    getMessages()
                }
            }
        });
    }

    const handleMessage = (clickedmessage) => {
        if(clickedmessage.type === "standard" || clickedmessage.type === "info"){
            getSpecific(clickedmessage.messageid)
        }else if(clickedmessage.type === "share"){
            setSharedMessage(clickedmessage)
            setSelectedMessage()
        }
    }

    const handleAccept = () => {
        setMessages([])
        getMessages()
        setSelectedMessage()
        setSharedMessage()
    }

    const backMessage = () => {
        setSelectedMessage()
        setSharedMessage()
    }

    const desktopCard = () => {
        return (
        <Dialog fullWidth={MOBILE ? true : true} maxWidth="md" open={show} onClose={handleClose}>
            <Card className="in_app_message_dialog">
                <div className="row m-0 p-0 border-settings">
                    <div className="col-5 m-0 p-0 section-divider">
                        <div className='d-flex align-items-center p-3'>
                            <img className='primary-icon ms-2' src={icons.envelope} alt=""/>
                            <h3 className="ms-3 primary">{t("inapp.messages")}</h3>
                        </div>
                    </div>
                    <div className="col-7 m-0 p-0">
                        <div className={`d-flex ${selectedMessage || sharedMessage ? "justify-content-between": "justify-content-end"} p-3`}>
                            {selectedMessage &&
                                <h3 className='primary text-truncate pe-3'>{selectedMessage.title}</h3>
                            }
                            {sharedMessage &&
                                <h3>{t("inapp.share_device")}</h3>
                            }
                            <img className="cursor-pointer" src={icons.close_gray} onClick={handleClose} alt="..." />
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 h-100">
                    <div className="col-5 m-0 p-0 section-divider message-list scrollable-y">
                        <div className="h-100">
                            {messages && messages.map((message, index) => {
                                return(
                                    <MessageRow 
                                        key={index} 
                                        message={message} 
                                        openMessage={selectedMessage && selectedMessage.messageid}
                                        sharedMessage={sharedMessage}
                                        index={index} 
                                        onClick={(clickedmessage) => handleMessage(clickedmessage)} />
                                )
                            }
                            )}
                            {(!messages || messages.length===0) &&
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <h3 className="dark_grey">{t("inapp.no_messages")}</h3>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col-7 m-0 p-0 message-window scrollable-y">
                        {selectedMessage 
                            ? <OpenMessage message={selectedMessage}/>
                            : sharedMessage
                            ? <SharedMessage message={sharedMessage} refreshList={() => handleAccept()}/>
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <h3 className="dark_grey">{t("inapp.select_message")}</h3>
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </Dialog>
        )
    }
    const mobileCard = () => {
        return (
        <Dialog fullWidth={MOBILE ? true : true} maxWidth="md" open={show} onClose={handleClose}    >
            <Card className="in_app_message_dialog">
                <div className="d-flex m-0 p-0 border-settings justify-content-between p-3">
                        <div className='d-flex align-items-center'>
                            <img className="size_25" src={selectedMessage || sharedMessage ? icons.back : icons.envelope} alt="" onClick={() => backMessage()}/>
                            <h3 className="ms-2">{selectedMessage ? selectedMessage.title : sharedMessage ? t("inapp.share_device") : t("inapp.messages")}</h3>
                        </div>
                        <img className="cursor-pointer" src={icons.close_gray} onClick={handleClose} alt="..." />

                </div>
                {!selectedMessage && !sharedMessage
                    ?
                    <div className="h-100">
                        {messages && messages.map((message, index) => (
                            <MessageRow 
                                key={index} 
                                message={message} 
                                openMessage={selectedMessage && selectedMessage.messageid}
                                sharedMessage={sharedMessage}
                                index={index} 
                                onClick={(clickedmessage) => handleMessage(clickedmessage)} />
                        ))}
                        {(!messages || messages.length===0) &&
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <h3 className="regular">{t("inapp.no_messages")}</h3>
                        </div>
                        }
                    </div>
                    :
                    <div className="message-window scrollable-y">
                        {selectedMessage 
                            ? <OpenMessage message={selectedMessage}/>
                            : sharedMessage
                            ? <SharedMessage message={sharedMessage} refreshList={() => handleAccept()}/>
                            :
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <h3 className="regular">{t("inapp.select_message")}</h3>
                            </div>
                        }
                    </div>
                }

            </Card>
        </Dialog>
        )
    }
 
    return(
        <>
        {MOBILE
        ? mobileCard()
        : desktopCard()
        }
        </>
    )
}

export default InAppModal;