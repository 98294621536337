import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink } from "react-router-dom";
// import { Modal } from 'react-bootstrap';
// import i18next from '../../i18n';
// import { INDEX_URL, CUST } from '../../helpers/constants';
// import { translateAlarms, translateDataplan, fixDate } from '../../helpers/helperFunctions';
// import Form from '../Form/Form';
// import FormInput from '../Form/FormInput';
// import FormSelect from '../Form/FormSelect';
// import Toast from '../toasts.js';
// import * as deviceService from '../../services/DeviceServiceFunctional';
import { SidebarData } from "../Sidebar/SidebarData";
import { useLocation, useNavigate } from 'react-router-dom'
import { INDEX_URL, CUST, icons, logos, FLAVOR } from "../../helpers/constants";
import LanguagePicker from '../LanguagePicker';
import { useTranslation } from 'react-i18next';
import * as request from "../../services/FetchService";
import InAppModal from '../Modals/InAppModal';

let intervalid;

const Sidebar = (props) => {
	const { t } = useTranslation();
	const [hoverpageTags, setHoverPageTags] = useState("")
	const [showModal, setShowModal] = useState(false)
	const location = useLocation();
	const navigate = useNavigate();
	const [looping, setLooping] = useState(false)

	const [notifications, setNotifications] = useState(
		{
			alarmicon: false,
			messageicon: false,
			startupmessage: {
				show: false,
				newuser: false
			}
		}
	)
	useEffect(() => {
		if(!showModal){
			getNewMessage()
		}
	}, [showModal])

	const getNewMessage = useCallback(() => {
		request.get("account/messagenotification/check").then((response) => {
		  if (response?.data?.successful) {
			const newData = response.data.data; // Assuming this is the structure returned from your API
			// Update notifications state with new data
			setNotifications(prevState => ({
			  ...prevState, // Preserve previous state
			  alarmicon: newData.alarmicon,
			  messageicon: newData.messageicon,
			  startupmessage: {
				...prevState.startupmessage, // Preserve previous nested state
				show: newData.startupmessage.show,
				newuser: newData.startupmessage.newuser
			  }
			}));
		  }
		});
	  }, []);


	  const startInterval = useCallback(() => {
		setLooping(true);
		intervalid = setInterval(getNewMessage, 60000);
	  }, [getNewMessage]);
	
	  const stopInterval = useCallback(() => {
		setLooping(false);
		clearInterval(intervalid);
	  }, []);
	
	  useEffect(() => {
		if (looping) {
		  startInterval();
		} else {
		  stopInterval();
		}
		return () => {
		  stopInterval(); // Cleanup function to stop interval when unmounting
		};
	  }, [looping, startInterval, stopInterval]);


	  useEffect(() => {
		const handleVisibilityChange = () => {
		  if (document.hidden) {
			stopInterval(); // Pause interval when window is in background
		  } else {
			startInterval(); // Resume interval when window is in foreground
		  }
		};
	  
		document.addEventListener("visibilitychange", handleVisibilityChange);
	  
		return () => {
		  document.removeEventListener("visibilitychange", handleVisibilityChange);
		  stopInterval(); // Cleanup function to stop interval when unmounting
		};
	  }, [startInterval, stopInterval]);



	useEffect(() => {
		if(location.pathname === "/events"){
			setNotifications((prev) => ({
				...prev,
				alarmicon: false
			}))
		}
	}, [location.pathname])

	return(
		<>
		<div className={`d-flex sidebar h-100`}>
			<div className="sidebar-inner pt-5 sidebar-item">
				<img src={logos.small} alt={""} className="w-100 size_25 mb-5 cursor-pointer nav-logo " onClick={() => navigate("/devices")}/>
			</div>
			{SidebarData.map((item, index) => (
				<NavLink
					key={index}
					className={`py-3 sidebar-item ${location.pathname === item.path ? "active-bg-gradient-grey" : "sidebar-inner"}`}
					to={item.path}
					onMouseEnter={() => setHoverPageTags(item.title)}
					onMouseLeave={() => setHoverPageTags("")}
				>
					<img
						src={item.icon_inactive}
						className={`${location.pathname === item.path ? "primary-icon" :""} cursor-pointer size_20`}
						alt="..."
					/>
					{item.path === "/events" && notifications.alarmicon &&
						<span className="position-absolute dot_alarm bg-offline top-0 mt-3 end-0 me-3"/>
					}
					<div className={`deviceTag ${(hoverpageTags === item.title && location.pathname !== item.path) ? "nametag_fade_in" : "nametag_fade_out"}`}>
						<h4>{t(item.title)}</h4>
					</div>
				</NavLink>
			))}
			<span className="sidebar-inner h-100 align-self-stretch" />
			<div className="position-absolute bottom-0 text-white w-100 sidebar-inner cursor-pointer align-self-end mb-4 ">
				<div className="sidebar-item" onClick={() => setShowModal(!showModal)}>
					<img src={icons.in_app} alt={""}/>
					{notifications.messageicon &&
						<span className="position-absolute dot_alarm bg-offline top-0 mt-0 end-0 me-3"/>
					}
				</div>
				<LanguagePicker />
			</div>

		</div>
		<InAppModal show={showModal} onClose={() => setShowModal(!showModal)}/>
		{/* <StartupModal show={notifications.startupmessage.show} newuser={notifications.startupmessage.newuser}/> */}
		</>
	)
}

export default Sidebar;